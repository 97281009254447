import { AxiosResponse } from 'axios';
import { config } from '../../../common/config/configurations';
import { instance } from '../../common/apis/baseApi';

const API_BASE_URL = `${config.REACT_APP_API_VERSION}/apps`;

interface SetupData {
  [key: string]: any;
}

const headers = {
  'Content-Type': 'multipart/form-data',
};

export const appSetupApi = {
  post: (data: SetupData): Promise<AxiosResponse> => instance.post(API_BASE_URL, data, { headers }),

  patch: (id: string, data: SetupData): Promise<AxiosResponse> =>
    instance.patch(`${API_BASE_URL}/${id}`, data, { headers }),

  get: (): Promise<AxiosResponse> =>
    instance.get(API_BASE_URL, {
      params: {
        type: 'organization_info',
      },
    }),

  postSetup: (data: SetupData, id: string | null): Promise<AxiosResponse> =>
    id ? appSetupApi.patch(id, data) : appSetupApi.post(data),
};
