import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { searchProducts } from '../apis/users';
import { searchProducts as searchProductsByCountry } from '@administration/apis/product';

const initialState = {
  products: [],
  count: 0,
  filter: {
    name: '',
  },
  loading: false,
  error: null,
};

export const fetchProducts = createAsyncThunk<any, { user_type: string; name: string }>(
  'products/fetchProducts',
  async ({ user_type, name = '' }) => {
    try {
      const response = await searchProducts(name, user_type);
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
      throw new Error('An error occurred while fetching suggestions');
    } catch (error) {
      throw new Error('An error occurred while fetching suggestions');
    }
  },
);

export const fetchProductsByCountry = createAsyncThunk<any, { country_id: string; name: string }>(
  'products/fetchProductsByCountry',
  async ({ country_id, name }) => {
    try {
      const response = await searchProductsByCountry(country_id, name);
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
      throw new Error('An error occurred while fetching suggestions');
    } catch (error) {
      throw new Error('An error occurred while fetching suggestions');
    }
  },
);

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setfilter(state, action: PayloadAction<string>) {
      state.filter.name = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload.values;
        state.count = action.payload.count || 0;
        state.error = null;
      })
      .addCase(fetchProducts.rejected, (state) => {
        state.loading = false;
        state.error = null;
      })

      .addCase(fetchProductsByCountry.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductsByCountry.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload.values;
        state.count = action.payload.count || 0;
        state.error = null;
      })
      .addCase(fetchProductsByCountry.rejected, (state) => {
        state.loading = false;
        state.error = null;
      });
  },
});

export const { setfilter } = productsSlice.actions;
export default productsSlice.reducer;
