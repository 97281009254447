import React, { createContext, useContext, useEffect, useState } from 'react';
import { renderPanelBasedOnKeys } from '../helpers/render-panel-keys';

interface KeyBoardHotKeyContextType {
  showPanel: boolean;
  setShowPanel: React.Dispatch<React.SetStateAction<boolean>>;
  keyComponent: React.ReactElement | null;
}

const KeyBoardHotKeyContext = createContext<KeyBoardHotKeyContextType | undefined>(undefined);

export const useHotKeys = () => {
  const context = useContext(KeyBoardHotKeyContext);
  if (!context) {
    throw new Error('useHotKeys must be used within a KeyBoardHotKeysProvider');
  }
  return context;
};

export const KeyBoardHotKeysProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [showPanel, setShowPanel] = useState<boolean>(false);
  const [keyComponent, setKeyComponent] = useState<React.ReactElement | null>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'd') {
        event.preventDefault();
        setShowPanel(true);
        setKeyComponent(renderPanelBasedOnKeys({ key: 'CTRL_D' }));
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const contextValue: KeyBoardHotKeyContextType = {
    showPanel,
    setShowPanel,
    keyComponent,
  };

  return (
    <KeyBoardHotKeyContext.Provider value={contextValue}>
      {children}
      {showPanel && keyComponent}
    </KeyBoardHotKeyContext.Provider>
  );
};

export default KeyBoardHotKeysProvider;
