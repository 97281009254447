import React from 'react';
import {
  DirectionsCarFilledOutlined,
  DirectionsWalkOutlined,
  DirectionsBikeOutlined,
  DirectionsTransitOutlined,
} from '@mui/icons-material';

export const TRAVEL_MODES: Record<string, React.ReactNode> = {
  DRIVING: <DirectionsCarFilledOutlined />,
  WALKING: <DirectionsWalkOutlined />,
  BICYCLING: <DirectionsBikeOutlined />,
  TRANSIT: <DirectionsTransitOutlined />,
};

export const MAXIMUM_DISTANCE_COVERED = 60;
