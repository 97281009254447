import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  isActivated: boolean;
} = {
  isActivated: false,
};

const countryFilterSlice = createSlice({
  name: 'countriesFilterSlice',
  initialState,
  reducers: {
    handleFilter: (state, action: PayloadAction<{ isActivated: boolean }>) => {
      state.isActivated = action.payload.isActivated;
    },
  },
});

export const { handleFilter } = countryFilterSlice.actions;
export default countryFilterSlice.reducer;
