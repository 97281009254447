import { Box, Tooltip, Typography } from '@mui/material';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { useSelector } from 'react-redux';
import { t } from 'i18next';

import { IInputIconProps } from '@administration/interfaces/IAppSetup';

export const InputContent: React.FC<IInputIconProps> = ({ id, selector, translationKey }) => {
  const { assets } = useSelector(selector);
  const assetContent = assets.find((a) => a.fileKey === id);

  return (
    <Box
      display="flex"
      flexDirection={assetContent?.fileName ? 'column' : 'row'}
      alignItems={assetContent?.fileName ? 'center' : 'center'}
      gap={2}
    >
      {assetContent?.fileURL ? (
        <img
          src={assetContent.fileURL}
          alt={assetContent.fileName ? assetContent.fileName : t(translationKey)}
          style={{
            maxWidth: '220px',
            maxHeight: '220px',
            borderRadius: '4px',
            objectFit: 'cover',
            marginTop: '24px',
          }}
        />
      ) : (
        <ImageOutlinedIcon sx={{ color: 'text.primary', fontSize: 26 }} />
      )}
      <Tooltip title={assetContent?.fileName ? assetContent.fileName : t(translationKey)}>
        <Typography
          variant="text-sm"
          sx={{
            textTransform: 'lowercase',
            maxWidth: '100px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {assetContent?.fileName ? assetContent.fileName : t(translationKey)}
        </Typography>
      </Tooltip>
    </Box>
  );
};
