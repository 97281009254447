import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchActiveCountries, fetchCountryProducts } from './notifications.action';
import { ICountryResponse } from '@administration/interfaces/ICountry';
import { IProductResponse } from '@administration/interfaces/IProduct';

type DayOfWeek = {
  label: string;
  value: string;
  selected: boolean;
  ariaLabel: string;
};

type TypeCadenceInterval = 'weekly' | 'monthly';
type TypeSettingsType = 'global' | 'specific';

interface NotificationSettingsState {
  cadenceInterval: TypeCadenceInterval;
  settingsType: TypeSettingsType;
  country: {
    values: any[];
    isLoading: boolean;
    selectedCountry: ICountryResponse | null;
  };
  product: {
    values: any[];
    isLoading: boolean;
    selectedProduct: IProductResponse | null;
  };
  days: DayOfWeek[];
  volume: number;
  onDay: string;
  monthCadence: string;
  shouldSeeMore: boolean;
  isProcessing: boolean;
}

const initialState: NotificationSettingsState = {
  cadenceInterval: 'monthly',
  settingsType: 'global',
  country: {
    values: [],
    isLoading: false,
    selectedCountry: null,
  },
  product: {
    values: [],
    isLoading: false,
    selectedProduct: null,
  },
  days: [
    { label: 'Mon', value: '1', selected: false, ariaLabel: 'Monday' },
    { label: 'Tue', value: '2', selected: false, ariaLabel: 'Tuesday' },
    { label: 'Wed', value: '3', selected: false, ariaLabel: 'Wednesday' },
    { label: 'Thu', value: '4', selected: false, ariaLabel: 'Thursday' },
    { label: 'Fri', value: '5', selected: false, ariaLabel: 'Friday' },
    { label: 'Sat', value: '6', selected: false, ariaLabel: 'Saturday' },
    { label: 'Sun', value: '7', selected: false, ariaLabel: 'Sunday' },
  ],
  volume: 10,
  onDay: '1',
  monthCadence: '1',
  shouldSeeMore: true,
  isProcessing: false,
};

const notificationSettingsSlice = createSlice({
  name: 'notificationSettings',
  initialState,
  reducers: {
    setCadenceInterval: (state, action: PayloadAction<TypeCadenceInterval>) => {
      state.cadenceInterval = action.payload;
    },
    setSettingsType: (state, action: PayloadAction<TypeSettingsType>) => {
      state.settingsType = action.payload;
    },
    setCountries: (state, action: PayloadAction<any[]>) => {
      state.country.values = action.payload;
    },
    setSelectedCountry: (state, action: PayloadAction<any | null>) => {
      state.country.selectedCountry = action.payload;
    },
    setProducts: (state, action: PayloadAction<any[]>) => {
      state.product.values = action.payload;
    },
    setSelectedProduct: (state, action: PayloadAction<any | null>) => {
      state.product.selectedProduct = action.payload;
    },
    setDays: (state, action: PayloadAction<DayOfWeek[]>) => {
      state.days = action.payload;
    },
    setVolume: (state, action: PayloadAction<number>) => {
      state.volume = action.payload;
    },
    setOnDay: (state, action: PayloadAction<string>) => {
      state.onDay = action.payload;
    },
    setMonthCadence: (state, action: PayloadAction<string>) => {
      state.monthCadence = action.payload;
    },
    setShouldSeeMore: (state, action: PayloadAction<boolean>) => {
      state.shouldSeeMore = action.payload;
    },
    setIsProcessing: (state, action: PayloadAction<boolean>) => {
      state.isProcessing = action.payload;
    },
    toggleDaySelection: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      state.days[index].selected = !state.days[index].selected;
    },
    clearState: (state) => {
      state.settingsType = 'global';
      state.volume = 10;
      state.onDay = '1';
      state.monthCadence = '1';
      state.shouldSeeMore = true;
      state.days = state.days.map((day) => ({ ...day, selected: false }));
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchActiveCountries
      .addCase(fetchActiveCountries.pending, (state) => {
        state.country.isLoading = true;
      })
      .addCase(fetchActiveCountries.fulfilled, (state) => {
        state.country.isLoading = false;
      })
      .addCase(fetchActiveCountries.rejected, (state) => {
        state.country.isLoading = false;
        state.country.values = [];
      })
      // fetchCountryProducts
      .addCase(fetchCountryProducts.pending, (state) => {
        state.product.isLoading = true;
      })
      .addCase(fetchCountryProducts.fulfilled, (state) => {
        state.product.isLoading = false;
      })
      .addCase(fetchCountryProducts.rejected, (state) => {
        state.product.isLoading = false;
        state.product.values = [];
      });
  },
});

export const {
  setCadenceInterval,
  setSettingsType,
  setCountries,
  setSelectedCountry,
  setProducts,
  setSelectedProduct,
  setDays,
  setVolume,
  setOnDay,
  setMonthCadence,
  setShouldSeeMore,
  setIsProcessing,
  toggleDaySelection,
  clearState,
} = notificationSettingsSlice.actions;

export default notificationSettingsSlice.reducer;
