import { combineReducers } from '@reduxjs/toolkit';
import mediaAuditSlice from './media-audit.slice';
import suggestionAuditSlice from './suggestions-audit.slice';

const auditReducer = combineReducers({
  mediaAudit: mediaAuditSlice,
  suggestionAudit: suggestionAuditSlice,
});

export * from './media-audit.slice';
export * from './suggestions-audit.slice';
export default auditReducer;
