import { createSlice } from '@reduxjs/toolkit';

import { IInitialStateFilters } from '../../../../../interfaces/IAdminUsers';

const initialState: IInitialStateFilters = {
  filterOptions: [
    {
      name: 'ADMIN_FILTERS_USERNAME',
      query: 'user_name',
      value: false,
    },
    {
      name: 'ADMIN_FILTERS_FAMILYNAME',
      query: 'family_name',
      value: true,
    },
    {
      name: 'ADMIN_FILTERS_EMAIL',
      query: 'mail',
      value: false,
    },
    {
      name: 'ADMIN_FILTERS_GIVENNAME',
      query: 'given_name',
      value: true,
    },
  ],
};

const userAdminFiltersSlice = createSlice({
  name: 'userAdminFiltersSlice',
  initialState,
  reducers: {
    handleFilters: (state, { payload }) => {
      const optionName = payload.payload;
      const copyOfFilterOption = [...state.filterOptions];
      const optionToUpdate = copyOfFilterOption.find((option: any) => option.name === optionName);
      if (optionToUpdate) {
        if (payload.type === 'toggle') {
          optionToUpdate.value = !optionToUpdate.value;
          state.filterOptions = copyOfFilterOption;
        } else if (payload.type === 'remove') {
          optionToUpdate.value = false;
          state.filterOptions = copyOfFilterOption;
        }
      }
    },
  },
});

export const { handleFilters } = userAdminFiltersSlice.actions;
export default userAdminFiltersSlice.reducer;
