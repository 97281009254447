import { AxiosResponse } from 'axios';
import { config } from '../../../common/config/configurations';
import { instance } from '../../common/apis/baseApi';
import { IQueryKey } from '@administration/interfaces/IQueryKey';
import { ICountry } from '@administration/interfaces/ICountry';

export const getActiveCountries = async (): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/countries`, {
    params: {
      $isActivated: true,
    },
  });
};

export const activateCountry = async (countryId: string): Promise<AxiosResponse> => {
  return instance.post(`${config.REACT_APP_API_VERSION}/countries/${countryId}/activate`);
};

export const deactivateCountry = async (countryId: string): Promise<AxiosResponse> => {
  return instance.post(`${config.REACT_APP_API_VERSION}/countries/${countryId}/deactivate`);
};

export const searchCountries = async ({
  queryKey,
}: {
  queryKey: IQueryKey;
}): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/countries/search`, {
    params: {
      query: queryKey.search,
      page_size: queryKey.pagination.pageSize,
      offset: queryKey.pagination.pageIndex,
      $count: true,
    },
  });
};

export const updateCountry = async (country: ICountry): Promise<AxiosResponse> => {
  return instance.put(`${config.REACT_APP_API_VERSION}/countries/${country.id}`, {
    ...country,
  });
};
