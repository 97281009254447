import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IConfigurationItem,
  IActivityThresholds,
  IUsecaseMarketshareState,
  TypeSettingsType,
} from '@administration/interfaces/IUsecases';

const initialState: IUsecaseMarketshareState = {
  title: 'NBA_CONFIGURATION_MARKET_SHARE',
  active: false,
  selectedOption: '',
  settingType: 'GLOBAL',
  activity_thresholds: {
    minimal_activity: {
      value: '',
      type: 'MOT',
    },
    low_alert_level: '',
    high_alert_level: '',
  },
};

const usecaseMarketShare = createSlice({
  name: 'usecaseMarketShare',
  initialState,
  reducers: {
    clearMarketShare: () => initialState,
    toggleActive: (state) => {
      state.active = !state.active;
    },
    updateOption: (state, action) => {
      state.selectedOption = action.payload;
    },
    updateMarketshareSettingType: (state, action: PayloadAction<TypeSettingsType>) => {
      state.settingType = action.payload;
    },
    updateActivityThresholds: (state, action: PayloadAction<IActivityThresholds>) => {
      state.activity_thresholds = action.payload;
    },
    prepareMarketShareForEdit: (state, action: PayloadAction<IConfigurationItem>) => {
      if (!action.payload) {
        return;
      }
      const { active, value, market_share_values } = action.payload;
      state.active = active;
      state.selectedOption = value || '';
      state.settingType = market_share_values?.type || 'GLOBAL';
      state.activity_thresholds = {
        minimal_activity: {
          value: market_share_values?.activity_thresholds.minimal_activity.value || '',
          type: market_share_values?.activity_thresholds.minimal_activity.type || 'MOT',
        },
        low_alert_level: market_share_values?.activity_thresholds.low_alert_level || '',
        high_alert_level: market_share_values?.activity_thresholds.high_alert_level || '',
      };
    },
  },
});

export const {
  clearMarketShare,
  toggleActive,
  updateOption,
  updateMarketshareSettingType,
  updateActivityThresholds,
  prepareMarketShareForEdit,
} = usecaseMarketShare.actions;
export default usecaseMarketShare.reducer;
