import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DAYS_OF_WEEK } from './constants';
import { IPersonalSettingsInitialState } from '@common/interfaces/IPreferences';
import { getPreferences } from '@common/apis/preferences';
import { FALLBACK_LANGUAGE } from '@common/i18n/i18nResources';
import i18next from 'i18next';
import { setTheme } from '@common/theme/theme.slice';

export const fetchPreferences = createAsyncThunk(
  'personalSettings/fetchPreferences',
  async (_, { dispatch }) => {
    const response = await getPreferences();
    if (response.status === 200 || response.status === 201) {
      dispatch(setTheme({ mode: response.data.theme }));
      return response.data;
    }
    throw new Error('Failed to fetch preferences');
  },
);

const initialState: IPersonalSettingsInitialState = {
  can_setup_policy: false,
  fetching: {
    isLoading: false,
    isError: false,
  },
  distribution_channels: {
    veeva: true,
    ms_outlook: false,
    ms_team: false,
  },
  notification_policy_frequency: {
    per: 'month',
    days: DAYS_OF_WEEK,
    onDay: 1,
    each: 1,
  },
  language: FALLBACK_LANGUAGE,
  notification_policy_volume: 30,
  timezone: 'GMT+01:00',
};

const personalSettingsSlice = createSlice({
  name: 'personalSettings',
  initialState,
  reducers: {
    setDistributionChannels: (
      state,
      action: PayloadAction<{ key: keyof typeof state.distribution_channels; value: boolean }>,
    ) => {
      state.distribution_channels[action.payload.key] = action.payload.value;
    },
    setWeeksFrequency: (state, action: PayloadAction<{ index: number }>) => {
      state.notification_policy_frequency.days[action.payload.index].selected =
        !state.notification_policy_frequency.days[action.payload.index].selected;
    },
    setNotificationPolicyFrequency: (
      state,
      action: PayloadAction<typeof state.notification_policy_frequency>,
    ) => {
      state.notification_policy_frequency = action.payload;
    },
    setTimezone: (state, action: PayloadAction<string>) => {
      state.timezone = action.payload;
    },
    setNotificationPolicyVolume: (state, action: PayloadAction<number>) => {
      state.notification_policy_volume = action.payload;
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPreferences.pending, (state) => {
        state.fetching.isLoading = true;
        state.fetching.isError = false;
      })
      .addCase(fetchPreferences.fulfilled, (state, action) => {
        state.fetching.isLoading = false;
        state.distribution_channels = action.payload.distribution_channels;
        state.notification_policy_volume = action.payload.notification_policy_volume;
        state.timezone = action.payload.timezone;
        state.can_setup_policy = action.payload.can_setup_policy;
        if (action.payload.notification_policy_frequency) {
          const { per, each, days } = action.payload.notification_policy_frequency;
          state.notification_policy_frequency.per = per;
          state.notification_policy_frequency.each = each;

          if (per === 'week') {
            const selectedDays = new Set(days.map((day: any) => day.label));
            state.notification_policy_frequency.days = DAYS_OF_WEEK.map((day) => ({
              ...day,
              selected: selectedDays.has(day.label),
            }));
          } else if (per === 'month') {
            state.notification_policy_frequency.onDay = days[0];
          }
        }

        if (action.payload.preferred_language) {
          state.language = action.payload.preferred_language;
          i18next.changeLanguage(action.payload.preferred_language);
        }
      })
      .addCase(fetchPreferences.rejected, (state) => {
        state.fetching.isLoading = false;
        state.fetching.isError = true;
      });
  },
});

export const {
  setDistributionChannels,
  setNotificationPolicyFrequency,
  setNotificationPolicyVolume,
  setWeeksFrequency,
  setLanguage,
  setTimezone,
} = personalSettingsSlice.actions;

export default personalSettingsSlice.reducer;
