import { AxiosResponse } from 'axios';
import { config } from '../../../common/config/configurations';
import { instance } from '../../common/apis/baseApi';

export function setNotificationSettings(data: any): Promise<AxiosResponse> {
  return instance.post(`/${config.REACT_APP_API_VERSION}/policies/notifications`, data);
}

export function getNotificationSettings(
  countryId: string,
  productId: string,
): Promise<AxiosResponse> {
  return instance.get(
    `/${config.REACT_APP_API_VERSION}/policies/notifications?product=${productId}&country=${countryId}`,
  );
}
