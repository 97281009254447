import { AxiosResponse } from 'axios';
import { config } from '../../../common/config/configurations';
import { instance } from '../../common/apis/baseApi';

export const processAudience = async (audienceId: string): Promise<AxiosResponse> => {
  return instance.post(`${config.REACT_APP_API_VERSION}/audiences/${audienceId}/process`);
};

export const getAudienceMembers = async (audienceId: string): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/audiences/${audienceId}/members/count`);
};
