import { Value, IAddresses } from '@common/interfaces/ISuggestions';

export const getUniqueHCPs = (suggestions: any) => {
  const Ids: any = [];
  const HCPs = suggestions.map((suggestion: Value) => suggestion.targets[0]);
  const uniqueHCPs = HCPs.filter((hcp: any) => {
    if (Ids.includes(hcp.id)) {
      return false;
    }
    Ids.push(hcp.id);
    return true;
  });

  return uniqueHCPs;
};

export const parseDuration = (duration: string) => {
  let days = 0,
    hours = 0,
    minutes = 0;

  const daysMatch = duration.match(/(\d+)\s*days?/);
  const hoursMatch = duration.match(/(\d+)\s*hours?/);
  const minsMatch = duration.match(/(\d+)\s*mins?/);

  if (daysMatch) {
    days = parseInt(daysMatch[1]);
  }
  if (hoursMatch) {
    hours = parseInt(hoursMatch[1]);
  }
  if (minsMatch) {
    minutes = parseInt(minsMatch[1]);
  }

  return days * 24 * 60 + hours * 60 + minutes;
};

export const parseDistance = (distance: string) => {
  const cleanedDistance = distance.replace(/\u00A0/g, ' ').trim();
  const match = cleanedDistance.match(/^([\d,]+(?:\.\d+)?)\s*(km|m|mi|ft)$/i);

  if (!match) {
    return 0;
  }

  const [, value, unit]: any = match;
  const parsed_value = parseFloat(value.replace(',', ''));

  switch (unit.toLowerCase()) {
    case 'm':
      return parsed_value / 1000;
    case 'mi':
      return parsed_value * 1.60934;
    case 'ft':
      return parsed_value * 0.0003048;
    case 'km':
    default:
      return parsed_value;
  }
};

export const getValidAddressCoords = (addresses: IAddresses[]): IAddresses | undefined => {
  const primaryAddress = addresses.find(
    (addr) =>
      addr.primary && typeof addr.latitude === 'number' && typeof addr.longitude === 'number',
  );
  if (primaryAddress) {
    return primaryAddress;
  }

  return addresses.find(
    (addr) => typeof addr.latitude === 'number' && typeof addr.longitude === 'number',
  );
};
