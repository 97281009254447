import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { auditMedia } from '../../../../../apis/audit';
import { IAuditState, IAuditResponse } from '../../../../../interfaces/IAudit';
import { IPagination } from '../../../../../interfaces/IPagination';

export const fetchMediaAudit = createAsyncThunk<IAuditResponse, IPagination>(
  'audit/fetchAudit',
  async ({ pageSize, offset }: IPagination, { rejectWithValue }) => {
    try {
      const response = await auditMedia({ pageSize, offset });
      if (response.status !== 200) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState: IAuditState = {
  count: 0,
  isLoading: false,
  isError: false,
  values: [],
};

const mediaAuditSlice = createSlice({
  name: 'audit/media',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMediaAudit.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchMediaAudit.fulfilled, (state, action: PayloadAction<IAuditResponse>) => {
        state.isLoading = false;
        state.values = action.payload.values;
        state.count = action.payload['$count'];
      })
      .addCase(fetchMediaAudit.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default mediaAuditSlice.reducer;
