export const castSuggestionsAuditResponse = (obj: any) => {
  if (!Array.isArray(obj)) {
    return [];
  }

  return obj.map((suggestionAuditObject: any) => {
    return {
      operation_id: suggestionAuditObject.id,
      started_at: suggestionAuditObject.created_at || 'N/A',
      status: suggestionAuditObject.status || 'N/A',
      name: suggestionAuditObject.name || 'N/A',
      description: suggestionAuditObject.type || 'N/A',
      finished_at: suggestionAuditObject.completed_at ? suggestionAuditObject.completed_at : 'N/A',
    };
  });
};
