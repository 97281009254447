import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUserDetails } from '@administration/apis/users/users';
import { IUserProfileState } from '@administration/interfaces/IUser';

export const fetchUserDetails = createAsyncThunk(
  'userDetails/fetchUserDetails',
  async (userId: string) => {
    const response = await getUserDetails(userId);
    return response.data;
  },
);

const initialState: IUserProfileState = {
  user: null,
  isLoading: false,
  error: null,
};

const userDetailsModal = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    addressUpdated(state, action) {
      if (state.user) {
        const address = state.user.addresses.find(
          (address) => address.id === action.payload.addressId,
        );
        if (address) {
          address.postalCode = action.payload['postal_code'];
          address.streetAddress = action.payload['street_address'];
          address.locality = action.payload.locality;
          address.region = action.payload.region;
        }
      }
    },
    emailUpdated(state, action) {
      if (state.user) {
        const email = state.user.emails.find((email) => email.id === action.payload.emailId);
        if (email) {
          email.value = action.payload.value;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
    });
    builder.addCase(fetchUserDetails.rejected, (state) => {
      state.isLoading = false;
      state.error = 'GLOBAL_ERROR';
    });
  },
});

export const { addressUpdated, emailUpdated } = userDetailsModal.actions;
export default userDetailsModal.reducer;
