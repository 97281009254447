import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { searchCountries as searchCountriesAPI } from '@administration/apis/country';
import { CountryState, ICountry } from '@administration/interfaces/ICountry';
import { IQueryKey } from '@administration/interfaces/IQueryKey';

export const searchCountries = createAsyncThunk<any, { queryKey: IQueryKey }>(
  'countrySlice/searchCountries',
  async ({ queryKey }) => {
    try {
      const response = await searchCountriesAPI({
        queryKey,
      });
      if (response.status === 200) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      return [];
    }
  },
);

const initialState: CountryState = {
  countries: [],
  count: 0,
  loading: false,
};

const countrySlice = createSlice({
  name: 'countrySlice',
  initialState,
  reducers: {
    updateCountry: (state, action: PayloadAction<{ country: ICountry }>) => {
      const index = state.countries.findIndex(
        (country) => country.id === action.payload.country.id,
      );

      if (index > -1) {
        state.countries[index] = action.payload.country;
      }
    },
  },
  extraReducers: (builder) => {
    // search country state
    builder.addCase(searchCountries.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchCountries.fulfilled, (state, action: PayloadAction<any>) => {
      state.countries = action.payload.values;
      state.loading = false;
      state.count = action.payload['$count'];
    });
    builder.addCase(searchCountries.rejected, (state) => {
      state.countries = [];
      state.loading = false;
      state.count = 0;
    });
  },
});

export const { updateCountry } = countrySlice.actions;
export default countrySlice.reducer;
