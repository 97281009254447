import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  IFilter,
  ISuggestionByProducts,
  ITopUsers,
  ISuggestionsSentByTime,
  ISuggestionByUseCase,
} from './types';
import { getDashboardAdoptionOverview } from '../apis/get-dashboard-overview';

const initialState = {
  dashboardAdoptionOverview: {
    suggestions_completed_percent: 0,
    suggestions_dismissed_percent: 0,
    suggestions_completed: 0,
    suggestions_dismissed: 0,
    suggestions_sent: 0,
    suggestions_actioned: 0,
    suggestions_actioned_percent: 0,
    suggestions_with_interaction: 0,
    suggestions_with_interaction_percent: 0,
    meeting_booked: 0,
    meeting_booked_percent: 0,
    total_users: 0,
    active_users: 0,
    suggestions_by_products: [] as ISuggestionByProducts[],
    top_users: [] as ITopUsers[],
    suggestions_sent_by_time: {} as ISuggestionsSentByTime,
    suggestions_by_usecase: [] as ISuggestionByUseCase[],
  },
  loading: false,
  error: null,
};

export const fetchDashboardAdoptionOverview = createAsyncThunk(
  'dashboardAdoptionOverview/fetchDashboardAdoptionOverview',
  async ({ filters }: { filters: IFilter | null }) => {
    try {
      const response = await getDashboardAdoptionOverview({
        filters,
      });
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
      throw new Error('An error occurred while fetching suggestions');
    } catch (error) {
      throw new Error('An error occurred while fetching suggestions');
    }
  },
);

const dashboardAdoptionOverviewSlice = createSlice({
  name: 'dashboardAdoptionOverview',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDashboardAdoptionOverview.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDashboardAdoptionOverview.fulfilled, (state, action) => {
        state.loading = false;
        state.dashboardAdoptionOverview = action.payload.values;
        state.error = null;
      })
      .addCase(fetchDashboardAdoptionOverview.rejected, (state) => {
        state.loading = false;
        state.error = null;
      });
  },
});

export default dashboardAdoptionOverviewSlice.reducer;
