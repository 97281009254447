import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

const AnimatedLogo = () => {
  return (
    <Box sx={{ margin: 'auto' }}>
      <Link to="/" style={{ display: 'flex' }}>
        <Box
          component="img"
          src="/assets/images/second_brain_logo_green_animated_strokes_draw.svg"
          alt="logo"
          sx={{ margin: 'auto', width: '60px' }}
        />
      </Link>
    </Box>
  );
};

export default AnimatedLogo;
