import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getActiveCountries } from '@administration/apis/country';
import { CountryState } from '@administration/interfaces/ICountry';

export const fetchActiveCountries = createAsyncThunk<any>(
  'activeCountriesSlice/fetchActiveCountries',
  async () => {
    try {
      const response = await getActiveCountries();
      if (response.status === 200) {
        return response.data.values;
      }
      throw new Error();
    } catch (error) {
      return [];
    }
  },
);

const initialState: CountryState = {
  countries: [],
  count: 0,
  loading: false,
};

const activeCountriesSlice = createSlice({
  name: 'activeCountriesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetch active countries
    builder.addCase(fetchActiveCountries.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchActiveCountries.fulfilled, (state, action: PayloadAction<any[]>) => {
      state.countries = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchActiveCountries.rejected, (state) => {
      state.countries = [];
      state.loading = false;
    });
  },
});

export default activeCountriesSlice.reducer;
