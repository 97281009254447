import { AxiosResponse } from 'axios';
import { config } from '../../../common/config/configurations';
import { instance } from '../../common/apis/baseApi';

export const getProducts = async (): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/products`, {
    params: {
      page_size: -1,
      offset: 0,
    },
  });
};

export const getProductsCountry = async (): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/usecases/search`, {
    params: {
      query: '',
      page_size: 10,
      offset: 0,
    },
  });
};

export const getProductsByCountryId = async (countryId: string): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/products`, {
    params: {
      page_size: -1,
      offset: 0,
      country_id: countryId,
    },
  });
};

export const getProduct = async (productId: string): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/products/${productId}`);
};

export const searchProducts = async (countryId: string, name: string): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/products/search`, {
    params: {
      page_size: 10,
      offset: 0,
      country_id: countryId,
      name,
    },
  });
};
