import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { RootState } from './types';
import suggestionReducer from '../common/pages/suggestions/suggestion.slice';
import sidebarReducer from '../administration/components/sidebar/sidebar-slice';
import userAdminFiltersReducer from '../administration/pages/administration/dashboard/users/slices/filters.slice';
import AdminUsersReducer from '../administration/pages/administration/dashboard/users/slices/admin-users.slice';
import toastReducer from '../administration/components/toast/toast.slice';
import countrySlice from '../administration/pages/administration/dashboard/countries/slices/country.slice';
import usecasePanelReducer from '../administration/pages/administration/dashboard/usecase/slices/usecase-panel.slice';
import usecasesReducer from '../administration/pages/administration/dashboard/usecase/slices/usecases.slice';
import auditReducer from '../administration/pages/administration/dashboard/audit/slices';
import hcpsReducer from '../common/slices/hcpSlice';
import hcosReducer from '../common/slices/hcoSlice';
import productsReducer from '../common/slices/productsSlice';
import usersReducer from '../common/slices/userSlice';
import suggestionsFilterReducer from '../common/components/filters/filter.slice';
import personalSettingsReducer from '../common/pages/settings/settings.slice';
import mapReducer from '../common/pages/suggestions/map.slice';
import directionsReducer from '../common/pages/suggestions/directions.slice';
import themeReducer from '../common/theme/theme.slice';
import userDetailsReducer from '../administration/pages/administration/dashboard/user-page/slices/user-details.slice';
import userDetailsModalReducer from '../administration/pages/administration/dashboard/user-page/slices/details-modal.slice';
import appSetupFormReducer from '../administration/pages/administration/dashboard/app-setup/slices/app-setup.slice';
import MSTeamsSetupReducer from '../administration/pages/administration/dashboard/msteams-setup/slices/msteams-setup.slice';
import MSTeamsActionsReducer from '../administration/pages/administration/dashboard/msteams-setup/slices/msteams-actions.slice';
import eventReducer from '../administration/pages/administration/dashboard/usecase/slices/event.slice';
import dashboardAdoptionOverviewFilterReducer from '../administration/pages/administration/dashboard/dashboard/adoption/services/slices/filter.slice';
import dashboardAdoptionOveriewReducer from '../administration/pages/administration/dashboard/dashboard/adoption/services/slices/overview.slice';
import notificationSettingsReducer from '../administration/pages/administration/dashboard/global-settings/slices/notifications.slice';
import groupMembersReducer from '../administration/pages/administration/dashboard/groups/slices/groups-members.slice';
import countryFilterReducer from '../administration/pages/administration/dashboard/countries/slices/filter.slice';
import countryFilterSearchReducer from '../administration/pages/administration/dashboard/countries/slices/search.slice';
import activeCountriesReducer from '@administration/pages/administration/dashboard/countries/slices/active-countries.slice';
import paramatersReducer from '../administration/pages/administration/dashboard/usecase/slices/paramaters.slice';
import usecaseMarketShareReducer from '../administration/pages/administration/dashboard/usecase/slices/marketshare.slice';
import usecaseAudienceReducer from '../administration/pages/administration/dashboard/usecase/slices/audience.slice';
import usecaseUserManagerReducer from '../administration/pages/administration/dashboard/usecase/slices/user-manager.slice';

import {
  ADMIN_SIDEBAR,
  ADMIN_USER_FILTERS,
  ADMIN_USERS,
  AUDIT,
  COUNTRIES,
  SUGGESTIONS,
  TOAST,
  USECASE_PANEL,
  USECASES,
  HCPS,
  HCOS,
  PRODUCTS,
  SUGGESTIONS_FILTER,
  PERSONAL_SETTINGS,
  USERS,
  MAP,
  DIRECTIONS,
  THEME,
  USER_DETAILS,
  USER_DETAILS_MODAL,
  APP_SETUP,
  MS_TEAMS_SETUP,
  MS_TEAMS_SETUP_ACTIONS,
  EVENT,
  DASHBOARD_ADOPTION_OVERVIEW_FILTER,
  DASHBOARD_ADOPTION_OVERVIEW,
  NOTIFICATION_SETTINGS,
  GROUP_MEMBERS,
  COUNTRY_FILTER,
  COUNTRY_FILTER_SEARCH,
  ACTIVE_COUNTRIES,
  USECASE_PARAMATERS,
  USECASE_MARKET_SHARE,
  USECASE_AUDIENCE,
  USECASE_USER_MANAGER,
} from './constants';
import debounceMiddleware from './utils';

const rootReducer = combineReducers({
  [SUGGESTIONS]: suggestionReducer,
  [ADMIN_SIDEBAR]: sidebarReducer,
  [ADMIN_USERS]: AdminUsersReducer,
  [ADMIN_USER_FILTERS]: userAdminFiltersReducer,
  [TOAST]: toastReducer,
  [COUNTRIES]: countrySlice,
  [USECASE_PANEL]: usecasePanelReducer,
  [USECASES]: usecasesReducer,
  [AUDIT]: auditReducer,
  [HCPS]: hcpsReducer,
  [HCOS]: hcosReducer,
  [PRODUCTS]: productsReducer,
  [USERS]: usersReducer,
  [SUGGESTIONS_FILTER]: suggestionsFilterReducer,
  [PERSONAL_SETTINGS]: personalSettingsReducer,
  [MAP]: mapReducer,
  [DIRECTIONS]: directionsReducer,
  [THEME]: themeReducer,
  [USER_DETAILS]: userDetailsReducer,
  [USER_DETAILS_MODAL]: userDetailsModalReducer,
  [APP_SETUP]: appSetupFormReducer,
  [MS_TEAMS_SETUP]: MSTeamsSetupReducer,
  [MS_TEAMS_SETUP_ACTIONS]: MSTeamsActionsReducer,
  [EVENT]: eventReducer,
  [DASHBOARD_ADOPTION_OVERVIEW_FILTER]: dashboardAdoptionOverviewFilterReducer,
  [DASHBOARD_ADOPTION_OVERVIEW]: dashboardAdoptionOveriewReducer,
  [NOTIFICATION_SETTINGS]: notificationSettingsReducer,
  [GROUP_MEMBERS]: groupMembersReducer,
  [COUNTRY_FILTER]: countryFilterReducer,
  [COUNTRY_FILTER_SEARCH]: countryFilterSearchReducer,
  [ACTIVE_COUNTRIES]: activeCountriesReducer,
  [USECASE_PARAMATERS]: paramatersReducer,
  [USECASE_MARKET_SHARE]: usecaseMarketShareReducer,
  [USECASE_AUDIENCE]: usecaseAudienceReducer,
  [USECASE_USER_MANAGER]: usecaseUserManagerReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (gdm) => gdm().concat(debounceMiddleware),
});

export const storeTest = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};
