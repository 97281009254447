import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUserDetails } from '@administration/apis/users/users';

export const fetchUsers = createAsyncThunk(
  'usecasePanel/fetchUsers',
  async (
    { users, type }: { users: string[]; type: 'INCLUDE' | 'EXCLUDE' },
    { rejectWithValue },
  ) => {
    const responses = await Promise.all(users.map((user) => getUserDetails(user)));
    const failedResponses = responses.filter((res) => res.status < 200 || res.status >= 300);
    const usersResponse = responses.map((res) => res.data);
    if (failedResponses.length > 0) {
      return rejectWithValue('GLOBAL_FETCHING_ERROR');
    }
    return { users: usersResponse, type };
  },
);
