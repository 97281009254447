import React from 'react';
import { InputContent } from '../../app-setup/components/input-content';
import { IAsset } from '@administration/interfaces/IAppSetup';
import { selectMSTeamsSetup } from '@store/selectors';

export const ASSETS: IAsset[] = [
  {
    // id used to map the assets to the key in the slice state
    id: 'light_small',
    iconContent: (
      <InputContent id="light_small" selector={selectMSTeamsSetup} translationKey="LIGHT_SMALL" />
    ),
    helperText: 'LIGHT_SMALL_HELPER_TEXT',
  },
  {
    id: 'dark_small',
    iconContent: (
      <InputContent id="dark_small" selector={selectMSTeamsSetup} translationKey="DARK_SMALL" />
    ),
    helperText: 'DARK_SMALL_HELPER_TEXT',
  },
];
