import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/types';
import { displayToast } from '@administration/components/toast/toast.slice';
import { t } from 'i18next';

import { fetchAssets, getAssetInformation, validateForm } from '../../app-setup/helpers';
import { ASSETS } from '../constants';
import { IMsTeamsSetupState, TypeKeys } from '@administration/interfaces/IAppSetup';
import { msTeamsApi } from '@administration/apis/app-setup/msteams';

export const fetchMsTeamsSetup = createAsyncThunk(
  'msTeams/fetch',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await msTeamsApi.get();
      if (response.status >= 200 && response.status < 300) {
        if (response.data?.error) {
          return rejectWithValue('NOT_FOUND');
        }
        const { id, app_name, images } = response.data.values[0];
        return { id, app_name, images };
      }
      return rejectWithValue('Failed to fetch app setup');
    } catch (error) {
      dispatch(displayToast({ message: t('GLOBAL_ERROR'), type: 'error' }));
      throw error;
    }
  },
);

export const submitMsTeamsSetup = createAsyncThunk(
  'msTeam/submit',
  async (_, { getState, dispatch }) => {
    const { msteamsSetup } = getState() as RootState;
    if (!validateForm(msteamsSetup)) {
      dispatch(displayToast({ keyMessage: 'GLOBAL_ALL_FIELDS_ARE_REQUIRED', type: 'error' }));
      return;
    }

    const formData = new FormData();
    formData.append('app_name', msteamsSetup.botName);
    if (!msteamsSetup.id) {
      formData.append('type', 'ms_team');
    }

    const toChangeAssets = msteamsSetup.assets.filter((asset) => !asset.uploaded);
    if (toChangeAssets.length > 0 || !msteamsSetup.id) {
      const assetFiles = await fetchAssets(toChangeAssets);
      assetFiles.forEach((value, key) => formData.append(key, value));
    }

    try {
      const response = await msTeamsApi.postSetup(formData, msteamsSetup.id);
      if (response.status >= 200 && response.status < 300) {
        const message = msteamsSetup.id
          ? t('GLOBAL_UPDATED_SUCCESSFULLY')
          : t('GLOBAL_SAVED_SUCCESSFULLY');
        dispatch(displayToast({ message, type: 'success' }));
        return response.data.values;
      }
      throw new Error('Failed to submit app setup');
    } catch (error) {
      dispatch(displayToast({ message: t('GLOBAL_ERROR'), type: 'error' }));
      throw error;
    }
  },
);

const initialState: IMsTeamsSetupState = {
  id: null,
  isLoading: false,
  botName: 'SecondBrain',
  assets: ASSETS.map((asset) => ({
    fileKey: asset.id,
    fileURL: `${process.env.PUBLIC_URL}/assets/icons/SB_logo_green.svg`,
    fileName: 'SecondBrain',
    fileType: 'svg',
    uploaded: false,
  })),
};

const msTeamsSetup = createSlice({
  name: 'msTeamsSetup',
  initialState,
  reducers: {
    setBotName: (state, action: PayloadAction<string>) => {
      state.botName = action.payload;
    },
    setMSTeamsAssets: (
      state,
      action: PayloadAction<{
        key: TypeKeys;
        file: { fileName: string; fileType: string; fileURL: string };
      }>,
    ) => {
      const { key, file } = action.payload;
      const asset = state.assets.find((asset) => asset.fileKey === key);
      if (asset) {
        Object.assign(asset, file, { uploaded: false });
      }
    },
    clearMSTeamsAssets: (state, action: PayloadAction<{ key: TypeKeys }>) => {
      const asset = state.assets.find((asset) => asset.fileKey === action.payload.key);
      if (asset) {
        asset.fileURL = null;
        asset.fileName = null;
        asset.fileType = null;
        asset.uploaded = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMsTeamsSetup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMsTeamsSetup.fulfilled, (state, action) => {
        const { id, app_name, images } = action.payload;
        state.isLoading = false;
        state.id = id;
        state.botName = app_name;
        state.assets.forEach((asset) => {
          const assetInfo = getAssetInformation(images, asset.fileKey);
          if (assetInfo) {
            Object.assign(asset, assetInfo, { uploaded: true, type: assetInfo.type });
          }
        });
      })
      .addCase(fetchMsTeamsSetup.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(submitMsTeamsSetup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(submitMsTeamsSetup.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(submitMsTeamsSetup.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setBotName, setMSTeamsAssets, clearMSTeamsAssets } = msTeamsSetup.actions;

export default msTeamsSetup.reducer;
