import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import ThemeWrapper from './common/theme';
import { store } from './store';
import router from './routes';
import { Toast } from './administration/components/toast/Toast';
import { ImpersonatedToast } from './administration/components/impersonateToast/ImpersonateToast';
import { LoginProvider } from './common/pages/login/login-context';
import { ToastProvider } from './administration/common/components/toast/ToastPovider';
import { initializeMS } from './common/pages/login/ms-services';
import { KeyBoardHotKeysProvider } from './common/context/hotkeys.context';
import './App.css';

function App() {
  useEffect(() => {
    initializeMS();
  }, []);

  return (
    <ToastProvider>
      <LoginProvider>
        <Provider store={store}>
          <KeyBoardHotKeysProvider>
            <ThemeWrapper>
              <RouterProvider router={router} />
              <Toast />
              <ImpersonatedToast />
            </ThemeWrapper>
          </KeyBoardHotKeysProvider>
        </Provider>
      </LoginProvider>
    </ToastProvider>
  );
}

export default App;
