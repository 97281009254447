import { createSlice } from '@reduxjs/toolkit';
import { IUsecaseSliceState } from '@administration/interfaces/IUsecases';
import { createUsecase, updateUsecase } from '../actions/usecase.action';

const initialState: IUsecaseSliceState = {
  isOpen: false,
  usecaseId: null,
  status: 'enabled',
  error: null,
  loading: 'idle',
};

const usecasePanel = createSlice({
  name: 'usecasePanel',
  initialState,
  reducers: {
    open: (state) => {
      state.isOpen = true;
    },
    close: (state) => {
      state.isOpen = false;
    },
    clearUsecase(state) {
      state.usecaseId = initialState.usecaseId;
      state.error = null;
    },
    setUsecaseId(state, action) {
      state.usecaseId = action.payload;
    },
    toggleStatus(state, action) {
      state.status = action.payload.status;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUsecase.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(createUsecase.fulfilled, (state) => {
        state.loading = 'succeeded';
      })
      .addCase(createUsecase.rejected, (state) => {
        state.loading = 'failed';
        state.error = 'GLOBAL_ERROR';
      })
      .addCase(updateUsecase.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(updateUsecase.fulfilled, (state) => {
        state.loading = 'succeeded';
      })
      .addCase(updateUsecase.rejected, (state) => {
        state.loading = 'failed';
        state.error = 'GLOBAL_ERROR';
      });
  },
});

export default usecasePanel.reducer;
export const { open, close, setUsecaseId, toggleStatus, clearUsecase } = usecasePanel.actions;
