import { AxiosResponse } from 'axios';
import { config } from '../../../common/config/configurations';
import { instance } from '../../common/apis/baseApi';
import { IPagination } from '../../interfaces/IPagination';

export function auditMedia({ pageSize, offset }: IPagination): Promise<AxiosResponse> {
  return instance.get(`${config.REACT_APP_API_VERSION}/media/files/processes`, {
    params: {
      page_size: pageSize,
      offset,
      $count: true,
    },
  });
}

export function auditSuggestions({ pageSize, offset }: IPagination): Promise<AxiosResponse> {
  return instance.get(`${config.REACT_APP_API_VERSION}/suggestions/processes`, {
    params: {
      page_size: pageSize,
      offset,
      $count: true,
    },
  });
}
