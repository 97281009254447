import { InputContent } from '../components/input-content';
import { IAsset } from '@administration/interfaces/IAppSetup';
import { CheckCircleOutlineOutlined } from '@mui/icons-material';
import { selectAppSetup } from '@store/selectors';

export const ASSETS: IAsset[] = [
  {
    // id used to map the assets to the key in the slice state
    id: 'light_small',
    iconContent: (
      <InputContent id="light_small" selector={selectAppSetup} translationKey="LIGHT_SMALL" />
    ),
    helperText: 'LIGHT_SMALL_HELPER_TEXT',
  },
  {
    id: 'light_long',
    iconContent: (
      <InputContent id="light_long" selector={selectAppSetup} translationKey="LIGHT_LARGE" />
    ),
    helperText: 'LIGHT_LONG_HELPER_TEXT',
  },
  {
    id: 'dark_small',
    iconContent: (
      <InputContent id="dark_small" selector={selectAppSetup} translationKey="DARK_SMALL" />
    ),
    helperText: 'DARK_SMALL_HELPER_TEXT',
  },
  {
    id: 'dark_long',
    iconContent: (
      <InputContent id="dark_long" selector={selectAppSetup} translationKey="DARK_LARGE" />
    ),
    helperText: 'DARK_LONG_HELPER_TEXT',
  },
];

export const APP_IMAGES_GUIDE = [
  {
    icon: <CheckCircleOutlineOutlined color="success" />,
    translationKey: 'SETUP_SUPPORTED_TYPES',
  },
  {
    icon: <CheckCircleOutlineOutlined color="success" />,
    translationKey: 'SETUP_MAKE_SMALL_SIZE_READABLE',
  },
];
