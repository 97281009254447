import { Middleware } from '@reduxjs/toolkit';
import { debounce } from 'lodash';

const debounceMiddleware: Middleware = () => {
  return (next) => (action: any) => {
    if (!action.meta || !action.meta.delay) {
      return next(action);
    }
    const debouncedAction = debounce(next, action.meta.delay);
    return debouncedAction(action);
  };
};

export const withDelay = (action: any, delay: number) => {
  return {
    ...action,
    meta: { delay },
  };
};

export default debounceMiddleware;
