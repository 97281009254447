import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { auditSuggestions } from '../../../../../apis/audit';
import { IAuditState, IAuditResponse } from '../../../../../interfaces/IAudit';
import { IPagination } from '../../../../../interfaces/IPagination';
import { castSuggestionsAuditResponse } from '../services';

export const fetchSuggestionsAudit = createAsyncThunk<IAuditResponse, IPagination>(
  'audit/fetchSuggestionsAudit',
  async ({ pageSize, offset }: IPagination, { rejectWithValue }) => {
    try {
      const response = await auditSuggestions({ pageSize, offset });
      if (response.status === 200) {
        return response.data;
      }
      return rejectWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState: IAuditState = {
  count: 0,
  isLoading: false,
  isError: false,
  values: [],
};

const suggestionAuditSlice = createSlice({
  name: 'audit/suggestions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSuggestionsAudit.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchSuggestionsAudit.fulfilled, (state, action: PayloadAction<IAuditResponse>) => {
        state.isLoading = false;
        state.values = castSuggestionsAuditResponse(action.payload.values);
        state.count = action.payload['$count'];
      })
      .addCase(fetchSuggestionsAudit.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default suggestionAuditSlice.reducer;
