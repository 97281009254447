import { createSlice } from '@reduxjs/toolkit';

const sidebarSlice = createSlice({
  name: 'adminSidebar',
  initialState: {
    isSidebarOpen: false,
  },
  reducers: {
    openSidebar: (state) => {
      state.isSidebarOpen = true;
    },
    closeSidebar: (state) => {
      state.isSidebarOpen = false;
    },
  },
});
export const { openSidebar, closeSidebar } = sidebarSlice.actions;
export default sidebarSlice.reducer;
