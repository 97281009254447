import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IEventParamaters, IEventState } from '@administration/interfaces/IEvent';
import { fetchEventAttendees, fetchEventData, fetchEventProducts } from '../actions/event.action';
import { CALL_INVITED, CALL_ATTENDANCE, EVENT_FOLLOWUP, EVENT_SECOND_FOLLOWUP } from '../constants';
import { IConfigurationItem } from 'administration/interfaces/IUsecases';

const initialState: IEventState = {
  loading: 'idle',
  eventData: null,
  attendeeStatus: null,
  event: {
    title: 'NBA_CONFIGURATION_MCCP_EVENT',
    active: false,
    types: [],
    statuses: [],
    topics: [],
    products: {
      error: null,
      laoding: 'idle',
      values: [],
    },
    configurations: {
      [CALL_INVITED]: {
        active: false,
        target: 'attendee',
        days: 0,
        priorities: [],
        attendee_status: [],
      },
      [CALL_ATTENDANCE]: {
        active: false,
        target: 'attendee',
        days: 0,
        priorities: [],
        attendee_status: [],
      },
      [EVENT_FOLLOWUP]: {
        active: false,
        target: 'attendee',
        days: 0,
        priorities: [],
        attendee_status: [],
      },
      [EVENT_SECOND_FOLLOWUP]: {
        active: false,
        target: 'attendee',
        days: 0,
        priorities: [],
        attendee_status: [],
      },
    },
  },
};

export const eventSlice = createSlice({
  name: 'usecaseEvent',
  initialState,
  reducers: {
    clearEvent: () => initialState,
    toggleEventActivation: (state, action: PayloadAction<boolean>) => {
      state.event.active = action.payload;
    },
    setSelectedEventProducts: (state, action) => {
      state.event.products.values = action.payload;
    },
    setEventParamaters: (state, action: PayloadAction<{ key: IEventParamaters; value: any }>) => {
      const { key, value } = action.payload;
      state.event[key] = value;
    },
    setEventConfiguration: (state, action: PayloadAction<{ key: string; config: any }>) => {
      const { key, config } = action.payload;
      if (state.event.configurations[key]) {
        state.event.configurations[key] = { ...state.event.configurations[key], ...config };
      }
    },
    prepareEventForEdit: (state, action: PayloadAction<IConfigurationItem>) => {
      const { active, configurations } = action.payload;
      state.event.active = active;
      state.event.types = configurations?.events.types || [];
      state.event.statuses = configurations?.events.statuses || [];
      state.event.topics = configurations?.events.topics || [];
      state.event.configurations[CALL_INVITED] = {
        ...state.event.configurations[CALL_INVITED],
        ...configurations?.calls[0],
      };
      state.event.configurations[CALL_ATTENDANCE] = {
        ...state.event.configurations[CALL_ATTENDANCE],
        ...configurations?.calls[1],
      };
      state.event.configurations[EVENT_FOLLOWUP] = {
        ...state.event.configurations[EVENT_FOLLOWUP],
        ...configurations?.calls[2],
      };
      state.event.configurations[EVENT_SECOND_FOLLOWUP] = {
        ...state.event.configurations[EVENT_SECOND_FOLLOWUP],
        ...configurations?.calls[3],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEventAttendees.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchEventAttendees.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.attendeeStatus = action.payload;
      })
      .addCase(fetchEventAttendees.rejected, (state) => {
        state.loading = 'failed';
        state.attendeeStatus = null;
      })
      .addCase(fetchEventData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchEventData.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.eventData = action.payload;
      })
      .addCase(fetchEventData.rejected, (state) => {
        state.loading = 'failed';
        state.eventData = null;
      })
      .addCase(fetchEventProducts.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchEventProducts.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.event.products.values = action.payload.products;
      })
      .addCase(fetchEventProducts.rejected, (state) => {
        state.loading = 'failed';
        state.event.products.error = 'GLOBAL_FETCHING_ERROR';
        state.event.products.values = [];
      });
  },
});

export const {
  clearEvent,
  setEventParamaters,
  setEventConfiguration,
  toggleEventActivation,
  setSelectedEventProducts,
  prepareEventForEdit,
} = eventSlice.actions;
export default eventSlice.reducer;
