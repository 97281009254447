import { RootState } from './types';

export const selectSuggestions = (state: RootState) => state.suggestions;
export const selectAdminSidebar = (state: RootState) => state.adminSidebar;
export const selectUserAdminFilters = (state: RootState) => state.userAdminFilters;
export const selectAdminUsers = (state: RootState) => state.adminUsers;
export const selectToast = (state: RootState) => state.toast;
export const selectCountries = (state: RootState) => state.countriesSlice;
export const selectUsecasePanel = (state: RootState) => state.usecasePanel;
export const selectUsecases = (state: RootState) => state.usecases;
export const selectAudit = (state: RootState) => state.audit;
export const selectHcps = (state: RootState) => state.hcps;
export const selectHcos = (state: RootState) => state.hcos;
export const selectProducts = (state: RootState) => state.products;
export const selectUsers = (state: RootState) => state.users;
export const selectSuggestionsFilter = (state: RootState) => state.suggestionsFilter;
export const selectPersonalSettings = (state: RootState) => state.personalSettings;
export const selectMap = (state: RootState) => state.map;
export const selectDirections = (state: RootState) => state.directions;
export const selectTheme = (state: RootState) => state.theme;
export const selectUserDetails = (state: RootState) => state.userDetails;
export const selectUserDetailsModal = (state: RootState) => state.userDetailsModal;
export const selectAppSetup = (state: RootState) => state.appSetup;
export const selectMSTeamsSetup = (state: RootState) => state.msteamsSetup;
export const selectMSTeamsActions = (state: RootState) => state.msTeamsActions;
export const selectUsecaseEvent = (state: RootState) => state.event;
export const selectDashboardAdoptionOverviewFilter = (state: RootState) =>
  state.dashboardAdoptionOverviewFilter;
export const selectDashboardAdoptionOverview = (state: RootState) =>
  state.dashboardAdoptionOverview;
export const selectNotificationSettings = (state: RootState) => state.notificationSettings;
export const selectGroupMembers = (state: RootState) => state.groupMembers;
export const selectCountriesFilter = (state: RootState) => state.countriesFilterSlice;
export const selectCountriesFilterSearch = (state: RootState) => state.countriesFilterSearch;
export const selectActiveCountries = (state: RootState) => state.activeCountriesSlice;
export const selectUsecaseParameters = (state: RootState) => state.usecaseParameters;
export const selectUsecaseMarketShare = (state: RootState) => state.usecaseMarketShare;
export const selectUsecaseAudience = (state: RootState) => state.usecaseAudience;
export const selectUsecaseUserManager = (state: RootState) => state.usecaseUserManager;
