import { IOption } from '@administration/interfaces/IUsecases';

export const OPTIONS: IOption[] = [
  { label: 'GLOBAL_NOT_APPLICABLE', name: 'Not applicable' },
  { label: 'GLOBAL_VERY_LOW', name: 'Very Low' },
  { label: 'GLOBAL_LOW', name: 'Low' },
  { label: 'GLOBAL_MEDIUM', name: 'Medium' },
  { label: 'GLOBAL_HIGH', name: 'High' },
  { label: 'GLOBAL_VERY_HIGH', name: 'Very High' },
];

export const NAMES_REGEX = /^([\w\s]+)\s*-\s*([\w\s–()-]+)$/;
export const PRODUCT_REGEX = /user\.product\.id\s*==\s*([a-f0-9\-]+)/;
export const COUNTRY_REGEX = /user\.country\.id\s*==\s*([a-f0-9\-]+)/;
export const USERS_TO_EXCLUDE_REGEX = /user\.id\s*!=\s*([a-f0-9\-]+)/g;
export const USERS_TO_INCLUDE_REGEX = /user\.id\s*==\s*([a-f0-9\-]+)/g;

export const PRIORITIES = [
  {
    translationKey: 'GLOBAL_ALL',
    value: 'ALL',
  },
  {
    translationKey: 'P1',
    value: 'P1',
  },
  {
    translationKey: 'P2',
    value: 'P2',
  },
  {
    translationKey: 'P3',
    value: 'P3',
  },
  {
    translationKey: 'P4',
    value: 'P4',
  },
  {
    translationKey: 'P5',
    value: 'P5',
  },
];

export const CALL_INVITED = 'callAcceptance';
export const CALL_ATTENDANCE = 'callAttendance';
export const EMAIL_ATTENDANCE = 'EmailAttendance';
export const EVENT_FOLLOWUP = 'eventFollowUp';
export const EVENT_SECOND_FOLLOWUP = 'eventSecondFollowUp';
