import React from 'react';
import { Snackbar, Alert, Button } from '@mui/material';
import { unImpersonateUser } from '../../apis/users/users';

export const ImpersonatedToast = () => {
  const [isOpen, setIsOpen] = React.useState(localStorage.getItem('impersonated') === 'true');

  const onClose = () => {
    unImpersonateUser()
      .then((response: any) => {
        if (response.status === 200) {
          window.location.href = '/administration/provisioning/users';
          setIsOpen(false);
          localStorage.removeItem('impersonated');
          localStorage.removeItem('user');
        }
      })
      .catch((error: any) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  return (
    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={isOpen}>
      <Alert
        severity={'warning'}
        variant="filled"
        sx={{ zIndex: 9999, width: '100%' }}
        action={
          <Button color="inherit" size="small" onClick={onClose}>
            Rollback
          </Button>
        }
      >
        You are impersonating a user. Click on rollback to stop impersonating.
      </Alert>
    </Snackbar>
  );
};
