import { AxiosResponse } from 'axios';
import { instance } from '../../common/apis/baseApi';
import { IPagination } from '../../interfaces/IPagination';
import { IGroup } from '../../common/interfaces/IGroup';
import { config } from '../../../common/config/configurations';

export function getGroups(
  organizationId: string | undefined,
  cursor = '',
  pageSize = 30,
): Promise<AxiosResponse> {
  const url = organizationId
    ? `/administration/organizations/${organizationId}/groups`
    : `${config.REACT_APP_API_VERSION}/groups`;
  return instance.get(url, {
    params: {
      $count: true,
      cursor: cursor,
      page_size: pageSize,
    },
  });
}

export function createGroup(
  organizationId: string | undefined,
  group: IGroup,
): Promise<AxiosResponse> {
  const url = organizationId
    ? `/administration/organizations/${organizationId}/groups`
    : `${config.REACT_APP_API_VERSION}/groups`;
  return instance.post(url, JSON.stringify(group), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function updateGroup(
  organizationId: string | undefined,
  id: string,
  group: IGroup,
): Promise<AxiosResponse> {
  const url = organizationId
    ? `/administration/organizations/${organizationId}/groups/${id}`
    : `${config.REACT_APP_API_VERSION}/groups/${id}`;

  return instance.put(url, JSON.stringify(group), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function deleteGroup(
  organizationId: string | undefined,
  id: string,
): Promise<AxiosResponse> {
  const url = organizationId
    ? `/administration/organizations/${organizationId}/groups/${id}`
    : `${config.REACT_APP_API_VERSION}/groups/${id}`;
  return instance.delete(url);
}

export function searchGroups(
  organizationId: string | undefined,
  pagination: IPagination,
  query: string,
  cursor = '',
): Promise<AxiosResponse> {
  const url = organizationId
    ? `/administration/organizations/${organizationId}/groups/search`
    : `${config.REACT_APP_API_VERSION}/groups/search`;
  return instance.get(url, {
    params: {
      query: query ? `name~${query}` : '',
      cursor: cursor,
      offset: pagination.offset,
      page_size: pagination.pageSize,
      count_members: true,
      $count: true,
    },
  });
}

export function countGroups(
  organizationId: string | undefined,
  query: string,
): Promise<AxiosResponse> {
  const url = organizationId
    ? `/administration/organizations/${organizationId}/groups/count`
    : `${config.REACT_APP_API_VERSION}/groups/count`;
  return instance.get(url, {
    params: {
      query: query,
    },
  });
}

export function getGroupMembers(
  groupId: string,
  query: string | undefined = undefined,
  offset = 0,
  pageSize = 30,
): Promise<AxiosResponse> {
  const url = `${config.REACT_APP_API_VERSION}/groups/${groupId}/members`;
  return instance.get(`${url}`, {
    params: {
      query: query,
      offset: offset,
      page_size: pageSize,
    },
  });
}

export function exportGroupMembers(groupId: string): Promise<AxiosResponse> {
  const url = `${config.REACT_APP_API_VERSION}/groups/${groupId}/members/export`;
  return instance.get(url, {
    responseType: 'blob',
  });
}
