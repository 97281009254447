import { Navigate } from 'react-router-dom';
import { ProtectedRoute } from '../common/components/authentication/protected';
import { PermittedRoute } from '../common/components/permissions/permittedRoute';
import { lazyWithRetry } from '../utils/lazy-retry';
import Loadable from '../common/components/loadable';

const AdminHome = Loadable(
  lazyWithRetry(() => import('../administration/pages/administration/dashboard/Home')),
);
const GlobalSettings = Loadable(
  lazyWithRetry(() => import('../administration/pages/administration/dashboard/global-settings')),
);
const Usecases = Loadable(
  lazyWithRetry(() => import('../administration/pages/administration/dashboard/usecase')),
);
const AdminUsers = Loadable(
  lazyWithRetry(() => import('../administration/pages/administration/dashboard/users')),
);
const UserPage = Loadable(
  lazyWithRetry(() => import('../administration/pages/administration/dashboard/user-page')),
);
const IQVIADataUpload = Loadable(
  lazyWithRetry(() => import('../administration/pages/administration/dashboard/iqvia')),
);
const AdminGroups = Loadable(
  lazyWithRetry(() => import('../administration/pages/administration/dashboard/groups')),
);
const GroupMembers = Loadable(
  lazyWithRetry(
    () => import('../administration/pages/administration/dashboard/groups/group-members'),
  ),
);
const AdminAudiences = Loadable(
  lazyWithRetry(() => import('../administration/pages/administration/dashboard/audiences')),
);
const AdminCycles = Loadable(
  lazyWithRetry(() => import('../administration/pages/administration/dashboard/cycles')),
);
const SuggestionsSettings = Loadable(
  lazyWithRetry(
    () => import('../administration/pages/administration/dashboard/suggestions-settings'),
  ),
);
const Audit = Loadable(
  lazyWithRetry(() => import('../administration/pages/administration/dashboard/audit')),
);
const Countries = Loadable(
  lazyWithRetry(() => import('../administration/pages/administration/dashboard/countries')),
);
const HCPAccounts = Loadable(
  lazyWithRetry(() => import('../administration/pages/administration/dashboard/accounts/hcp')),
);
const AdminCyclesPlans = Loadable(
  lazyWithRetry(() => import('../administration/pages/administration/dashboard/cycles-plans')),
);
const AdminCyclesPlansTargets = Loadable(
  lazyWithRetry(
    () => import('../administration/pages/administration/dashboard/cycles-plans-targets'),
  ),
);
const DysportUpload = Loadable(
  lazyWithRetry(() => import('../administration/pages/administration/dashboard/dysport-upload')),
);

const AppSetup = Loadable(
  lazyWithRetry(() => import('../administration/pages/administration/dashboard/app-setup')),
);

const AdminDashboardAdoption = Loadable(
  lazyWithRetry(
    () => import('../administration/pages/administration/dashboard/dashboard/adoption'),
  ),
);

const AdminDashboardSetup = Loadable(
  lazyWithRetry(() => import('../administration/pages/administration/dashboard/dashboard/setup')),
);

const MSTeamsSetup = Loadable(
  lazyWithRetry(() => import('../administration/pages/administration/dashboard/msteams-setup')),
);

export const adminRoutes = {
  path: '/administration',
  element: (
    <ProtectedRoute>
      <PermittedRoute
        accessRules={{ roles: ['country', 'data', 'it', 'platform', 'global_admin'] }}
      >
        <AdminHome mode="web" />
      </PermittedRoute>
    </ProtectedRoute>
  ),
  children: [
    {
      index: true,
      element: <Navigate to="dashboard/adoption" replace />,
    },
    {
      path: 'settings/global',
      element: <GlobalSettings mode="web" />,
    },
    {
      path: 'settings/usecases',
      element: <Usecases mode="web" />,
    },
    {
      path: 'settings/suggestions',
      element: <SuggestionsSettings mode="web" />,
    },
    {
      path: 'settings/countries',
      element: <Countries mode="web" />,
    },
    {
      path: 'settings/app-setup',
      element: <AppSetup />,
    },
    {
      path: 'settings/msteams-setup',
      element: <MSTeamsSetup mode="web" />,
    },
    {
      path: 'data/iqvia/upload',
      element: <IQVIADataUpload mode="web" />,
    },
    {
      path: 'data/audit',
      element: <Audit mode="web" />,
    },
    {
      path: 'data/veeva/cycle',
      element: <AdminCycles mode="web" />,
    },
    {
      path: 'data/veeva/dysport-upload',
      element: <DysportUpload />,
    },
    {
      path: 'data/veeva/cycles-plans',
      element: <AdminCyclesPlans mode="web" />,
    },
    {
      path: 'data/veeva/cycle-plans-targets',
      element: <AdminCyclesPlansTargets mode="web" />,
    },
    {
      path: 'provisioning/users',
      element: <AdminUsers mode="web" />,
    },
    {
      path: 'provisioning/users/:userId',
      element: <UserPage />,
    },
    {
      path: 'provisioning/groups',
      element: <AdminGroups mode="web" />,
    },
    {
      path: 'provisioning/groups/:groupId/members',
      element: <GroupMembers />,
    },
    {
      path: 'provisioning/audiences',
      element: <AdminAudiences mode="web" />,
    },
    {
      path: 'dashboard/adoption',
      element: <AdminDashboardAdoption mode="web" />,
    },
    {
      path: 'dashboard/setup',
      element: <AdminDashboardSetup mode="web" />,
    },
    {
      path: 'accounts/hcps',
      element: <HCPAccounts mode="web" />,
    },
  ],
};
