import { getActiveCountries } from '@administration/apis/country';
import {
  getNotificationSettings,
  setNotificationSettings,
} from '@administration/apis/notifications';
import { getProductsByCountryId } from '@administration/apis/product';
import { displayToast } from '@administration/components/toast/toast.slice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '@store/types';
import { t } from 'i18next';
import {
  clearState,
  setCadenceInterval,
  setCountries,
  setDays,
  setIsProcessing,
  setOnDay,
  setProducts,
  setSettingsType,
  setShouldSeeMore,
  setVolume,
} from './notifications.slice';

export const fetchActiveCountries = createAsyncThunk(
  'notifications/fetchActiveCountries',
  async (_, { dispatch }) => {
    try {
      const response = await getActiveCountries();
      if (response.status === 200) {
        const data = response.data.values;
        data.unshift({ id: 'ALL', name: t('GLOBAL_ALL') });
        dispatch(setCountries(data));
      }
    } catch (error) {
      dispatch(displayToast({ type: 'error', keyMessage: 'GLOBAL_FETCHING_ERROR' }));
    }
  },
);

export const fetchCountryProducts = createAsyncThunk(
  'notifications/fetchCountryProducts',
  async (countryId: string, { dispatch }) => {
    try {
      const response = await getProductsByCountryId(countryId);
      if (response.status === 200) {
        const data = response.data.values;
        data.unshift({ id: 'ALL', name: t('GLOBAL_ALL') });
        dispatch(setProducts(data));
      }
    } catch (error) {
      dispatch(displayToast({ type: 'error', keyMessage: 'GLOBAL_FETCHING_ERROR' }));
    }
  },
);

export const fetchNotificationSettings = createAsyncThunk(
  'notifications/fetchNotificationSettings',
  async (_, { getState, dispatch }) => {
    const initialState = (getState() as RootState).notificationSettings;
    const { country, product } = initialState;
    if (!country.selectedCountry || !product.selectedProduct) {
      return;
    }
    try {
      const response = await getNotificationSettings(
        country.selectedCountry.id,
        product.selectedProduct.id,
      );
      if (response.status === 200) {
        const data = response.data.values;
        if (data.length > 0) {
          dispatch(
            displayToast({ type: 'success', keyMessage: 'NOTIFICATION_SETTINGS_ALREADY_SAVED' }),
          );
          dispatch(setSettingsType(data[0].type));
          dispatch(setVolume(data[0].volume));
          dispatch(setShouldSeeMore(data[0].enable_user_setting));
          if (data[0].cadence.per === 'week') {
            dispatch(setCadenceInterval('weekly'));
            dispatch(
              setDays(
                initialState.days.map((day) => ({
                  ...day,
                  selected: !!data[0].cadence.days.find((x: any) => x.label === day.label),
                })),
              ),
            );
          } else {
            dispatch(setCadenceInterval('monthly'));
            dispatch(setOnDay(data[0].cadence.days[0]));
          }
        } else {
          dispatch(clearState());
          dispatch(displayToast({ type: 'info', keyMessage: 'GLOBAL_NO_SETTINGS_SAVED' }));
        }
      }
    } catch (error) {
      dispatch(clearState());
      dispatch(displayToast({ type: 'error', keyMessage: 'GLOBAL_FETCHING_ERROR' }));
    }
  },
);

export const saveNotificationSettings = createAsyncThunk(
  'notifications/saveNotificationSettings',
  async (_, { getState, dispatch }) => {
    const state = (getState() as RootState).notificationSettings;
    const { country, product, cadenceInterval, settingsType, days, volume, onDay, shouldSeeMore } =
      state;
    if (!country.selectedCountry || !country.selectedCountry.id) {
      dispatch(displayToast({ type: 'error', keyMessage: 'GLOBAL_COUNTRY_REQUIRED' }));
      return;
    }
    if (!product.selectedProduct || !product.selectedProduct.id) {
      dispatch(displayToast({ type: 'error', keyMessage: 'GLOBAL_PRODUCT_REQUIRED' }));
      return;
    }
    if (cadenceInterval === 'monthly' && (!onDay || !state.monthCadence)) {
      dispatch(displayToast({ type: 'error', keyMessage: 'GLOBAL_NOT_VALID_ENTRIES' }));
      return;
    }
    if (cadenceInterval === 'monthly' && parseInt(onDay) > 31) {
      dispatch(displayToast({ type: 'error', keyMessage: 'GLOBAL_DAY_OUT_OF_RANGE' }));
      return;
    }
    if (cadenceInterval === 'weekly' && !days.find((day) => day.selected)) {
      dispatch(displayToast({ type: 'error', keyMessage: 'GLOBAL_WEEK_DAY_NOTSELECTED' }));
      return;
    }
    if (!volume) {
      dispatch(displayToast({ type: 'error', keyMessage: 'GLOBAL_VOLUME_REQUIRED' }));
      return;
    }

    dispatch(setIsProcessing(true));

    const postData = {
      product: product.selectedProduct.id,
      country: country.selectedCountry.id,
      type: settingsType,
      cadence: {
        per: cadenceInterval === 'weekly' ? 'week' : 'month',
        days:
          cadenceInterval === 'weekly'
            ? days.filter((day) => day.selected).map(({ selected, ariaLabel, ...rest }) => rest)
            : [onDay],
      },
      enable_user_setting: shouldSeeMore,
      volume: volume,
    };

    try {
      const postResponse = await setNotificationSettings(postData);
      if (postResponse.status === 201) {
        dispatch(displayToast({ type: 'success', keyMessage: 'NOTIFICATION_SETTINGS_SAVED' }));
      } else {
        dispatch(
          displayToast({
            type: 'error',
            keyMessage: t('GLOBAL_SAVING_ERROR', { context: t('GLOBAL_NOTIFICATIONS') }),
          }),
        );
      }
    } catch (error) {
      dispatch(
        displayToast({
          type: 'error',
          keyMessage: t('GLOBAL_SAVING_ERROR', { context: t('GLOBAL_NOTIFICATIONS') }),
        }),
      );
    } finally {
      dispatch(setIsProcessing(false));
    }
  },
);
