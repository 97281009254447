import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { IPagination } from '@administration/interfaces/IQueryKey';
import { RootState } from '@store/types';
import { searchCountries } from './country.slice';
import { buildQueryParams } from '../helpers/build-query';

const initialState: {
  pagination: IPagination;
  search: string;
} = {
  pagination: {
    pageSize: 10,
    pageIndex: 0,
  },
  search: '',
};

export const onSubmitFilterSearch = createAsyncThunk(
  'countriesFilterSearch/onSubmitFilterSearch',
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const isActivated = state.countriesFilterSlice.isActivated;
    const filters = ['name'];
    await dispatch(
      searchCountries({
        queryKey: {
          pagination: state.countriesFilterSearch.pagination,
          search: buildQueryParams(state.countriesFilterSearch.search, filters, isActivated),
        },
      }),
    );
  },
);

const countryFilterSearchSlice = createSlice({
  name: 'countryFilterSearch',
  initialState,
  reducers: {
    setSearch: (state, { payload }) => {
      state.search = payload;
    },
    setPagination: (state, action: PayloadAction<{ pagination: IPagination }>) => {
      state.pagination.pageSize = action.payload.pagination.pageSize;
      state.pagination.pageIndex = action.payload.pagination.pageIndex;
    },
  },
});

export const { setSearch, setPagination } = countryFilterSearchSlice.actions;
export default countryFilterSearchSlice.reducer;
