import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { app, geoLocation } from '@microsoft/teams-js';

type Coordinates = { lat: number; lng: number };

interface MapState {
  isMapVisible: boolean;
  center: google.maps.LatLngLiteral | undefined;
  userLocation: google.maps.LatLngLiteral | undefined;
  isUserLocated: boolean;
  error: string;
}

export const fetchCurrentPosition = createAsyncThunk<Coordinates, void, { rejectValue: string }>(
  'map/fetchCurrentPosition',
  async (_, { rejectWithValue }) => {
    try {
      if (app.isInitialized()) {
        const location = await geoLocation.getCurrentLocation();
        return {
          lat: location.latitude,
          lng: location.longitude,
        };
      }

      const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });

      if (permissionStatus.state === 'denied') {
        return rejectWithValue('GEO_PERMISSION_DENIED');
      }

      if (permissionStatus.state === 'granted' || permissionStatus.state === 'prompt') {
        const position = await new Promise<GeolocationPosition>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          });
        });

        return {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      }

      return rejectWithValue('GEO_PERMISSION_DENIED');
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'GLOBAL_ERROR');
    }
  },
);

const initialState: MapState = {
  isMapVisible: false,
  center: undefined,
  userLocation: undefined,
  isUserLocated: false,
  error: '',
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    toggleMapView: (state) => {
      state.isMapVisible = !state.isMapVisible;
    },
    setCurrentPosition: (state, action: PayloadAction<Coordinates>) => {
      state.userLocation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentPosition.pending, (state) => {
        state.isUserLocated = false;
        state.error = '';
      })
      .addCase(fetchCurrentPosition.fulfilled, (state, action) => {
        state.isUserLocated = true;
        state.center = action.payload;
        state.userLocation = action.payload;
        state.error = '';
      })
      .addCase(fetchCurrentPosition.rejected, (state, action) => {
        state.error = action.payload ?? 'GLOBAL_ERROR';
        state.isUserLocated = false;
        state.userLocation = undefined;
      });
  },
});

export const { setCurrentPosition, toggleMapView } = mapSlice.actions;
export default mapSlice.reducer;
