import { AxiosResponse } from 'axios';
import { config } from '../../../common/config/configurations';
import { instance } from '../../common/apis/baseApi';
import { IMsTeamsUserActionsPayload } from '@administration/interfaces/IAppSetup';

const API_BASE_URL = `${config.REACT_APP_API_VERSION}/apps`;
const COMMUNICATION_URL = `${config.REACT_APP_API_VERSION}/communication`;

const headers = {
  'Content-Type': 'multipart/form-data',
};

export const msTeamsApi = {
  post: (data: any): Promise<AxiosResponse> => instance.post(API_BASE_URL, data, { headers }),

  patch: (id: string, data: any): Promise<AxiosResponse> =>
    instance.patch(`${API_BASE_URL}/${id}`, data, { headers }),

  get: (): Promise<AxiosResponse> =>
    instance.get(`${API_BASE_URL}`, {
      params: {
        type: 'ms_team',
      },
    }),

  postSetup: (data: any, id: string | null): Promise<AxiosResponse> =>
    id ? msTeamsApi.patch(id, data) : msTeamsApi.post(data),
};

export const communicationApi = {
  postWelcomeMessage: (data: IMsTeamsUserActionsPayload): Promise<AxiosResponse> =>
    instance.post(`${COMMUNICATION_URL}/welcome`, data),
  postSuggestionMessage: (data: IMsTeamsUserActionsPayload): Promise<AxiosResponse> =>
    instance.post(`${COMMUNICATION_URL}/suggestion`, data),
  postVerificationMessage: (data: IMsTeamsUserActionsPayload): Promise<AxiosResponse> =>
    instance.post(`${COMMUNICATION_URL}/check`, data),
};
