export const buildQueryParams = (
  searchText: string,
  filters: any[],
  isActivated: boolean,
): string => {
  const query =
    searchText &&
    filters
      .map((filter: string) => {
        return `${filter}~${searchText}`;
      })
      .join(' OR ');

  return isActivated ? (query ? `${query} AND active==true` : 'active==true') : query;
};
