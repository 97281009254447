import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { patchUserAddress, patchUserEmail } from '@administration/apis/users/users';
import { RootState } from '@store/types';
import { editFlagType } from '@administration/interfaces/IUser';
import { displayToast } from '@administration/components/toast/toast.slice';
import { addressUpdated, emailUpdated } from './user-details.slice';

export const updateUserEmail = createAsyncThunk(
  'userDetails/patchUserEmail',
  async ({ emailId, payload }: { emailId: string; payload: any }, { getState, dispatch }) => {
    const state = getState() as RootState;
    const userId = state.userDetails.user?.id;
    if (!userId) {
      throw new Error('user Id not found');
    }
    const response = await patchUserEmail({ userId, emailId, payload });
    if (response.status >= 200 && response.status < 300) {
      dispatch(emailUpdated({ emailId, value: payload.value }));
      dispatch(displayToast({ message: t('GLOBAL_UPDATED_SUCCESSFULLY'), type: 'success' }));
    } else {
      dispatch(
        displayToast({
          message: t('GLOBAL_SAVING_ERROR', { context: t('GLOBAL_EMAIL') }),
          type: 'error',
        }),
      );
      throw new Error('Error updating email');
    }
    return response.data;
  },
);

export const updateUserAddress = createAsyncThunk(
  'userDetails/patchUserAddress',
  async ({ addressId, payload }: { addressId: string; payload: any }, { getState, dispatch }) => {
    const state = getState() as RootState;
    const userId = state.userDetails.user?.id;
    if (!userId) {
      throw new Error('user Id not found');
    }
    const response = await patchUserAddress({ userId, addressId, payload });
    if (response.status >= 200 && response.status < 300) {
      dispatch(addressUpdated({ addressId, ...payload }));
      dispatch(displayToast({ message: t('GLOBAL_UPDATED_SUCCESSFULLY'), type: 'success' }));
    } else {
      dispatch(
        displayToast({
          message: t('GLOBAL_SAVING_ERROR', { context: t('GLOBAL_ADDRESS') }),
          type: 'error',
        }),
      );
      throw new Error('Error updating address');
    }
    return response.data;
  },
);

export interface IDetailsModalState {
  isModalOpen: boolean;
  isProcessing: boolean;
  isSuccess: boolean;
  payload: any;
  error: string | null;
  modalType: string | null;
  modalIndex?: number;
}

const initialState: IDetailsModalState = {
  isModalOpen: false,
  isProcessing: false,
  isSuccess: false,
  payload: {},
  error: null,
  modalType: null,
  modalIndex: undefined,
};

const detailsModal = createSlice({
  name: 'userDetailsModal',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<{ modalType: editFlagType; modalIndex?: number }>) => {
      const { modalType } = action.payload;
      state.isModalOpen = true;
      state.modalType = modalType;
      state.modalIndex = action.payload.modalIndex;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
    },
  },
  extraReducers: (builder) => {
    // email
    builder.addCase(updateUserEmail.pending, (state) => {
      state.isProcessing = true;
    });
    builder.addCase(updateUserEmail.fulfilled, (state) => {
      state.isProcessing = false;
      state.isSuccess = true;
    });
    builder.addCase(updateUserEmail.rejected, (state) => {
      state.isProcessing = false;
      state.error = 'GLOBAL_ERROR';
    });
    // address
    builder.addCase(updateUserAddress.pending, (state) => {
      state.isProcessing = true;
    });
    builder.addCase(updateUserAddress.fulfilled, (state) => {
      state.isProcessing = false;
      state.isSuccess = true;
    });
    builder.addCase(updateUserAddress.rejected, (state) => {
      state.isProcessing = false;
      state.error = 'GLOBAL_ERROR';
    });
  },
});

export const { openModal, closeModal } = detailsModal.actions;
export default detailsModal.reducer;
