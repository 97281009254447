import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUsers, searchUsers } from '../../../../../apis/users/users';
import { objectCasting } from '../helpers/admin-users.service';
import {
  IUserData,
  IFetchUsersParams,
  IInitialState,
  IfilterParams,
  IOrderParams,
} from '../../../../../interfaces/IAdminUsers';

export const searchForUsers = createAsyncThunk<IUserData, string>(
  'users/searchForUsers',
  async (searchQuery: string) => {
    const response = await searchUsers(searchQuery);
    if (response.status !== 200) {
      throw new Error('Failed to fetch users');
    }
    return response.data;
  },
);

export const fetchUsers = createAsyncThunk<IUserData, IFetchUsersParams>(
  'users/fetchUsers',
  async ({ pageSize, offset, cursor = null }) => {
    const response = await getUsers({ pageSize, offset, cursor });
    if (response.status !== 200) {
      throw new Error('Failed to fetch users');
    }
    return response.data;
  },
);

const initialState: IInitialState = {
  users: [],
  usersBackup: [],
  count: 0,
  totalUsers: 0,
  cursor: null,
  loading: false,
  error: false,
};

const AdminUsersSlice = createSlice({
  name: 'AdminUsers',
  initialState,
  reducers: {
    orderUsers: (state, action: PayloadAction<IOrderParams>) => {
      const { id, desc } = action.payload;
      if (id === 'name') {
        state.users.sort((a, b) => {
          if (desc) {
            return a.displayName.localeCompare(b.displayName);
          }
          return b.displayName.localeCompare(a.displayName);
        });
      }
    },
    filterUsers: (state, action: PayloadAction<IfilterParams>) => {
      const { id, value } = action.payload;
      if (id === 'country') {
        const filteredUsers = state.users.filter((user) => user['country'] === value);
        state.users = filteredUsers;
      } else if (id === 'name') {
        if (!value || value === ' ' || typeof value !== 'string') {
          state.users = [];
        } else {
          const filteredUsers = state.users.filter((user) =>
            user.displayName.toLowerCase().includes(value.toLowerCase()),
          );
          state.users = filteredUsers;
        }
      }
    },
    resetUsers: (state) => {
      state.users = state.usersBackup;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action: PayloadAction<IUserData>) => {
      state.loading = false;
      state.users = objectCasting(action.payload.users);
      state.usersBackup = objectCasting(action.payload.users);
      state.count = action.payload.count;
      state.cursor = action.payload.cursor ?? null;
      state.totalUsers = action.payload['$count'];
    });
    builder.addCase(fetchUsers.rejected, (state) => {
      state.users = [];
      state.loading = false;
      state.error = true;
    });
    // Search for users
    builder.addCase(searchForUsers.fulfilled, (state, action: any) => {
      state.users = objectCasting(action.payload.values);
      state.loading = false;
      state.totalUsers = action.payload.count;
    });
    builder.addCase(searchForUsers.pending, (state) => {
      state.users = [];
      state.error = false;
      state.loading = true;
    });
    builder.addCase(searchForUsers.rejected, (state) => {
      state.users = [];
      state.loading = false;
      state.error = true;
    });
  },
});

export const { orderUsers, filterUsers, resetUsers } = AdminUsersSlice.actions;
export default AdminUsersSlice.reducer;
