import { createAsyncThunk } from '@reduxjs/toolkit';
import { MRT_PaginationState, MRT_RowSelectionState } from 'material-react-table';

import { deleteUsecase, getUsecases, postUsecase, putUsecase } from '@administration/apis/usecases';
import { IPagination } from '@administration/interfaces/IPagination';
import { IUsecase } from '@administration/interfaces/IUsecases';
import { USERS_TO_EXCLUDE_REGEX, USERS_TO_INCLUDE_REGEX } from '../constants';
import { RootState } from '@store/types';
import { prepareAudienceForEdit } from '../slices/audience.slice';
import { prepareParamatersForEdit } from '../slices/paramaters.slice';
import { prepareMarketShareForEdit } from '../slices/marketshare.slice';
import { prepareEventForEdit } from '../slices/event.slice';
import { fetchEventProducts } from './event.action';
import { fetchUsers } from './user-manager.action';
import { open, setUsecaseId } from '../slices/usecase-panel.slice';
import { preparePayload, validateEntries } from '../utils';

export const fetchUsecases = createAsyncThunk(
  'usecases/fetchUsecases',
  async ({ pageSize, offset }: IPagination) => {
    const response = await getUsecases({ pageSize, offset });
    if (response.status < 200 || response.status >= 300) {
      throw new Error('An error occurred while fetching suggestions');
    }
    return response.data;
  },
);

export const toggleUsecaseStatus = createAsyncThunk(
  'usecases/toggleUsecaseStatus',
  async ({ id, status }: { id: string; status: string }) => {
    const currentStatus = status === 'enabled' ? 'disabled' : 'enabled';
    const response = await putUsecase(id, { status: currentStatus });
    if (response.status < 200 || response.status >= 300) {
      throw new Error('An error occurred while updating usecase status');
    }
    return { id, status: currentStatus };
  },
);

export const deleteSelectedUsecases = createAsyncThunk(
  'usecases/deleteSelectedUsecases',
  async (rows: MRT_RowSelectionState, { dispatch }) => {
    const selectedRows = Object.keys(rows || {});
    const responses = await Promise.all(selectedRows.map(deleteUsecase));
    const failedResponses = responses.filter((response) => response.status !== 204);
    if (failedResponses.length > 0) {
      dispatch({
        type: 'toast/displayToast',
        payload: {
          type: 'error',
          keyMessage: 'GLOBAL_DELETE_FAILED',
        },
      });
      throw new Error('An error occurred while deleting usecases');
    } else {
      dispatch({
        type: 'toast/displayToast',
        payload: {
          type: 'success',
          keyMessage: 'GLOBAL_DELETE_SUCCESS',
        },
      });
      return selectedRows;
    }
  },
);

export const prepareUsecaseForEdit = createAsyncThunk(
  'usecasePanel/prepareUsecaseForEdit',
  async (usecaseId: string, { getState, dispatch }) => {
    const state = getState() as RootState;
    const usecases = state.usecases.usecases;
    const toEditUsecase = usecases.find((usecase: IUsecase) => usecase.id === usecaseId);
    if (!toEditUsecase) {
      dispatch({
        type: 'toast/displayToast',
        payload: { type: 'error', keyMessage: 'GLOBAL_ERROR' },
      });
      return;
    }
    dispatch(open());
    dispatch(setUsecaseId(usecaseId));
    const rule = toEditUsecase.audiences[0].rule;
    const usersIdsToExclude = [...rule.matchAll(USERS_TO_EXCLUDE_REGEX)].map((match) => match[1]);
    const usersIdsToInclude = [...rule.matchAll(USERS_TO_INCLUDE_REGEX)].map((match) => match[1]);
    dispatch(fetchUsers({ users: usersIdsToExclude, type: 'EXCLUDE' }));
    dispatch(fetchUsers({ users: usersIdsToInclude, type: 'INCLUDE' }));
    dispatch(prepareAudienceForEdit(toEditUsecase.audiences[0]));
    dispatch(prepareParamatersForEdit(toEditUsecase.flow.configuration));
    const productsIds = toEditUsecase.flow.configuration.event.configurations?.events?.product_ids;
    if (productsIds) {
      dispatch(fetchEventProducts(productsIds));
    }

    dispatch(prepareEventForEdit(toEditUsecase.flow.configuration.event));
    dispatch(prepareMarketShareForEdit(toEditUsecase.flow.configuration.market_share));
  },
);

export const createUsecase = createAsyncThunk(
  'usecases/create',
  async (pagination: MRT_PaginationState, { dispatch, getState, rejectWithValue }) => {
    const {
      usecasePanel,
      event,
      usecaseAudience,
      usecaseUserManager,
      usecaseMarketShare,
      usecaseParameters,
    } = getState() as RootState;
    const args = {
      isCreation: true,
      usecasePanel,
      event,
      usecaseAudience,
      usecaseUserManager,
      usecaseMarketShare,
      usecaseParameters,
    };
    const isEntriesValid = validateEntries(args, dispatch);
    if (!isEntriesValid) {
      return rejectWithValue('Validation failed');
    }
    const payload = preparePayload(args);
    try {
      const response = await postUsecase(payload);
      if (response.status >= 200 && response.status < 300) {
        dispatch(fetchUsecases({ pageSize: pagination.pageSize, offset: pagination.pageIndex }));
        dispatch({
          type: 'toast/displayToast',
          payload: { type: 'success', keyMessage: 'GLOBAL_CREATED_SUCCESSFULLY' },
        });
      } else {
        dispatch({
          type: 'toast/displayToast',
          payload: { type: 'error', keyMessage: 'GLOBAL_ERROR' },
        });
        return rejectWithValue('Creation failed');
      }
    } catch (_error) {
      dispatch({
        type: 'toast/displayToast',
        payload: { type: 'error', keyMessage: 'GLOBAL_ERROR' },
      });
      return rejectWithValue('Unknown error');
    }
  },
);

export const updateUsecase = createAsyncThunk(
  'usecases/update',
  async ({ pagination }: { pagination: MRT_PaginationState }, { dispatch, getState, rejectWithValue }) => {
    const {
      usecasePanel,
      event,
      usecaseAudience,
      usecaseUserManager,
      usecaseMarketShare,
      usecaseParameters,
    } = getState() as RootState;

    const args = {
      isCreation: false,
      usecasePanel,
      event,
      usecaseAudience,
      usecaseUserManager,
      usecaseMarketShare,
      usecaseParameters,
    };

    const isEntriesValid = validateEntries(args, dispatch);
    if (!isEntriesValid) {
      return rejectWithValue('Validation failed');
    }

    const payload = preparePayload(args);
    if (!usecasePanel.usecaseId) {
      return rejectWithValue('Usecase ID is missing');
    }

    try {
      const response = await putUsecase(usecasePanel.usecaseId, payload);
      if (response.status >= 200 && response.status < 300) {
        dispatch({
          type: 'toast/displayToast',
          payload: { type: 'success', keyMessage: 'GLOBAL_UPDATED_SUCCESSFULLY' },
        });
        dispatch(fetchUsecases({ pageSize: pagination.pageSize, offset: pagination.pageIndex }));
      } else {
        dispatch({
          type: 'toast/displayToast',
          payload: { type: 'error', keyMessage: 'GLOBAL_ERROR' },
        });
        return rejectWithValue('Update failed');
      }
    } catch (_error) {
      dispatch({
        type: 'toast/displayToast',
        payload: { type: 'error', keyMessage: 'GLOBAL_ERROR' },
      });
      return rejectWithValue('Unknown error');
    }
  }
);

