import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IConfigurations, IUsecaseParamatersState } from '@administration/interfaces/IUsecases';

const initialState: IUsecaseParamatersState = {
  NBA_CONFIGURATION_MCCP_COVERAGE: { active: true },
  NBA_CONFIGURATION_PRIORITIES: { active: true },
  NBA_CONFIGURATION_ROLE: { active: false, selectedOption: '' },
  NBA_CONFIGURATION_RELATIVE_STRENGTH: { active: false, selectedOption: '' },
  NBA_CONFIGURATION_ADOPTION_LADDER: { active: false, selectedOption: '' },
  NBA_CONFIGURATION_TOP_ACCOUNTS: { active: false, selectedOption: '' },
  NBA_CONFIGURATION_HCP_SPECIALTY: { active: false, selectedOption: '' },
  NBA_CONFIGURATION_HCP_TYPE: { active: false, selectedOption: '' },
};

const ParamatersSlice = createSlice({
  name: 'usecaseParamaters',
  initialState,
  reducers: {
    clearParamaters: () => initialState,
    toggleActive: (state, action: PayloadAction<string>) => {
      const key = action.payload;
      if (state[key]) {
        state[key].active = !state[key].active;
      }
    },
    updateOption: (state, action: PayloadAction<{ key: string; option: string }>) => {
      const { key, option } = action.payload;
      if (state[key] && state[key].selectedOption !== undefined) {
        state[key].selectedOption = option;
      }
    },
    prepareParamatersForEdit: (state, action: PayloadAction<IConfigurations>) => {
      Object.keys(state).forEach((key) => {
        const configItem = Object.values(action.payload).find((config) => config.name === key);
        if (configItem) {
          const { name, active, value } = configItem;
          state[name] = {
            active: active,
            selectedOption: value,
          };
        }
      });
    },
  },
});

export const { clearParamaters, toggleActive, updateOption, prepareParamatersForEdit } =
  ParamatersSlice.actions;
export default ParamatersSlice.reducer;
