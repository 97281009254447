import { AxiosResponse } from 'axios';
import { config } from '@administration/common/config/configurations';
import { IFilter } from '../slices/types';
import { instance } from '@administration/common/apis/baseApi';

export function getDashboardAdoptionOverview({
  filters,
}: {
  filters: IFilter | null;
}): Promise<AxiosResponse> {
  const url = `/${config.REACT_APP_API_VERSION}/dashboard/adoption/overview`;

  return instance.post(url, {
    selected_countries_ids: filters?.countries,
    selected_products_ids: filters?.products,
    timeframe: filters?.date?.type,
    start_date: filters?.date?.date?.start_date,
    end_date: filters?.date?.date?.end_date,
  });
}
