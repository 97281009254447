import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { searchHcos } from '../apis/users';

const initialState = {
  hcos: [],
  filter: {
    name: '',
  },
  suggestions: [],
  count: 0,
  loading: false,
  error: null,
};

export const fetchHcos = createAsyncThunk<any, { user_type: string; name: string }>(
  'hcos/fetchHcos',
  async ({ user_type, name = '' }) => {
    try {
      const response = await searchHcos(name, user_type);
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
      throw new Error('An error occurred while fetching suggestions');
    } catch (error) {
      throw new Error('An error occurred while fetching suggestions');
    }
  },
);

const hcosSlice = createSlice({
  name: 'hcos',
  initialState,
  reducers: {
    setfilter(state, action: PayloadAction<string>) {
      state.filter.name = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchHcos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHcos.fulfilled, (state, action) => {
        state.loading = false;
        state.hcos = action.payload.values;
        state.count = action.payload.count || 0;
        state.error = null;
      })
      .addCase(fetchHcos.rejected, (state) => {
        state.loading = false;
        state.error = null;
      });
  },
});

export const { setfilter } = hcosSlice.actions;
export default hcosSlice.reducer;
