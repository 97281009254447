import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAudienceMembers } from '@administration/apis/audience';

export const fetchAudiencesMembers = createAsyncThunk(
  'usecasePanel/fetchAudiencesMembers',
  async (audienceId: string, { rejectWithValue }) => {
    const response = await getAudienceMembers(audienceId);
    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue('CAN_NOT_FETCH_AUDIENCE_MEMBERS');
    }
    return response.data;
  },
);
