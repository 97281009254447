import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { searchSuggestions } from '../../apis/suggestions';
import { ISuggestionsSliceState, TypeSuggestionsAction } from '../../interfaces/ISuggestions';
import { IFilter } from '../../interfaces/IFilters';
import { IPagination } from '@administration/interfaces/IPagination';
import { getUniqueHCPs } from './helpers';

const initialState: ISuggestionsSliceState = {
  suggestions: [],
  uniqueHcps: [],
  count: 0,
  loading: false,
  error: null,
};

export const fetchSuggestions = createAsyncThunk(
  'suggestions/fetchSuggestions',
  async ({
    filters,
    pagination,
    user_type,
    last_batch_only,
  }: {
    filters: IFilter | null;
    pagination: IPagination;
    user_type: string;
    last_batch_only?: boolean;
  }) => {
    try {
      const response = await searchSuggestions({
        filters,
        pagination,
        user_type,
        last_batch_only: last_batch_only,
      });
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
      throw new Error('An error occurred while fetching suggestions');
    } catch (error) {
      throw new Error('An error occurred while fetching suggestions');
    }
  },
);

const suggestionsSlice = createSlice({
  name: 'suggestions',
  initialState,
  reducers: {
    updateSuggestionStatus: (
      state,
      action: PayloadAction<{
        suggestionId: string;
        status: TypeSuggestionsAction;
      }>,
    ) => {
      const { suggestionId, status } = action.payload;
      const updatedSuggestions = state.suggestions.values.map((suggestion: any) => {
        if (suggestion.id === suggestionId) {
          suggestion.suggestion_mapping.status = status;
        }
        return suggestion;
      });
      state.suggestions.values = updatedSuggestions;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSuggestions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSuggestions.fulfilled, (state, action) => {
        state.loading = false;
        state.suggestions = action.payload.values;
        state.uniqueHcps = getUniqueHCPs(action.payload.values);
        state.count = action.payload.count;
        state.error = null;
      })
      .addCase(fetchSuggestions.rejected, (state) => {
        state.loading = false;
        state.error = null;
      });
  },
});

export const { updateSuggestionStatus } = suggestionsSlice.actions;
export default suggestionsSlice.reducer;
