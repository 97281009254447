import {
  IAppSetupFormState,
  IFileState,
  IMsTeamsSetupState,
} from '@administration/interfaces/IAppSetup';

export const getFileFromURL = async (url: string, name: string): Promise<File> => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], name, { type: blob.type });
};

export const fetchAssets = async (assets: IFileState[]): Promise<Map<string, File>> => {
  const assetFiles = new Map<string, File>();

  await Promise.all(
    assets
      .filter((asset) => !asset.uploaded)
      .map(async (asset) => {
        if (!asset.fileURL) {
          return;
        }
        try {
          if (!asset.fileName) {
            return;
          }
          const file = await getFileFromURL(asset.fileURL, asset.fileName);
          assetFiles.set(`${asset.fileKey}_${asset.fileName}`, file);
        } catch (error) {
          return;
        }
      }),
  );
  return assetFiles;
};

export const validateForm = (appSetupForm: IAppSetupFormState | IMsTeamsSetupState): boolean => {
  const name =
    'applicationName' in appSetupForm ? appSetupForm.applicationName : appSetupForm.botName;
  const { assets } = appSetupForm;
  if (!name) {
    return false;
  }
  return assets.every((asset: any) => asset?.fileURL !== null);
};

export const getAssetInformation = (images: any, type: any) => {
  const image = images.find((img: any) => img.type.startsWith(type));
  if (!image) {
    return null;
  }

  return {
    fileURL: image.link,
    fileName: image.type.substring(type.length + 1),
    fileType: image.type.split('.').pop(),
    type: image.type,
  };
};

export const updateMetaData = (name: string, images: any, mode: 'light' | 'dark') => {
  document.title = name;
  let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.head.appendChild(link);
  }
  const smallImage = images.find((img: any) => img.type.startsWith(`${mode}_small`));
  if (smallImage) {
    link.href = smallImage.link;
  }
};
