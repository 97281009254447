import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { fetchDashboardAdoptionOverview } from './overview.slice';
import { FilterName, FilterType } from './types';

import { RootState } from '@store/types';

const initialState: {
  filterState: Record<FilterName, { name: string; selectedFilters: any }>;
} = {
  filterState: {
    products: { name: 'products', selectedFilters: [] },
    countries: { name: 'countries', selectedFilters: [] },
    date: {
      name: 'date',
      selectedFilters: {
        type: 'last_month',
        date: {
          start_date: null,
          end_date: null,
        },
      },
    },
  },
};

export const onSubmitFilterDashboardAdoptionOverview = createAsyncThunk(
  'dashboardAdoptionOverviewFilter/onSubmitFilterDashboardAdoptionOvervie',
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const filters = {
      products: state.dashboardAdoptionOverviewFilter.filterState.products.selectedFilters.map(
        (item: any) => item.id,
      ),
      countries: state.dashboardAdoptionOverviewFilter.filterState.countries.selectedFilters.map(
        (item: any) => item.id,
      ),
      date: {
        type: state.dashboardAdoptionOverviewFilter.filterState.date.selectedFilters.type,
        date: {
          start_date:
            state.dashboardAdoptionOverviewFilter.filterState.date.selectedFilters.date.start_date,
          end_date:
            state.dashboardAdoptionOverviewFilter.filterState.date.selectedFilters.date.end_date,
        },
      },
    };

    if (
      filters.countries.length > 0 &&
      (filters.date.type !== 'custom' ||
        (filters.date.type === 'custom' &&
          filters.date.date.start_date &&
          filters.date.date.end_date))
    ) {
      await dispatch(
        fetchDashboardAdoptionOverview({
          filters,
        }),
      );
    }
  },
);

const dashboardAdoptionOverviewFilterSlice = createSlice({
  name: 'dashboardAdoptionOverviewFilter',
  initialState,
  reducers: {
    setFilter: (
      state,
      action: PayloadAction<{
        filter: any;
        filterName: FilterName;
        filterType: FilterType;
      }>,
    ) => {
      switch (action.payload.filterName) {
        case 'countries':
        case 'products':
          state.filterState[action.payload.filterName].selectedFilters = action.payload.filter;
          break;
        case 'date':
          if (action.payload.filterType === 'normal') {
            state.filterState.date.selectedFilters.type = action.payload.filter;
          } else {
            state.filterState.date.selectedFilters.type = 'custom';
            state.filterState.date.selectedFilters.date.start_date =
              action.payload.filter.start_date;
            state.filterState.date.selectedFilters.date.end_date = action.payload.filter.end_date;
          }
          break;
        default:
          break;
      }
    },
  },
});

export const { setFilter } = dashboardAdoptionOverviewFilterSlice.actions;
export default dashboardAdoptionOverviewFilterSlice.reducer;
