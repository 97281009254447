import React from 'react';

const DebugPanel = React.lazy(() => import('../components/shortcut-views/debug-panel'));

type IKeys = 'CTRL_D';

const UIObjects: Record<IKeys, React.ReactElement> = {
  CTRL_D: <DebugPanel />,
};

export const renderPanelBasedOnKeys = ({ key }: { key: IKeys }): React.ReactElement => {
  return <React.Suspense fallback={<></>}>{UIObjects[key]}</React.Suspense>;
};
